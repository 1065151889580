import React, { useState } from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  brandColours,
  fontWeights,
  fontSize,
} from '../styles';
import { Container, Heading, HtmlContent } from './ui';

const StyledAccordion = styled.section`
  ${sectionMargins()};
`;

const StyledHeading = styled(Heading)`
  text-align: center;
  margin-bottom: 20px;

  ${minBreakpointQuery.small`
    margin-bottom: 30px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 40px;
  `}
`;

const StyledItem = styled.article`
  margin: 10px 0;
  padding: 25px 30px;
  color: ${brandColours.quaternary};
  background-color: ${brandColours.primary};
  border-radius: 50px;

  ${minBreakpointQuery.small`
    padding: 30px 40px;
  `}

  ${minBreakpointQuery.large`
    padding: 35px 50px;
  `}
`;

const StyledSubHeading = styled.h3`
  position: relative;
  padding-right: 30px;
  ${fontSize(19)};
  line-height: 1.5;
  cursor: pointer;

  ${minBreakpointQuery.small`
    padding-right: 35px;
    ${fontSize(22)};
  `}

  ${minBreakpointQuery.large`
    padding-right: 40px;
    ${fontSize(25)};
  `}

  &:after {
    content: ${({ display }) => (display ? "'-'" : "'+'")};
    position: absolute;
    top: 50%;
    right: 0;
    font-weight: ${fontWeights.regular};
    color: ${brandColours.quaternary};
    ${fontSize(30)};
    transform: translateY(-50%);

    ${minBreakpointQuery.small`
      ${fontSize(35)};
    `}

    ${minBreakpointQuery.large`
      ${fontSize(40)};
    `}
  }
`;

const StyledText = styled(HtmlContent)`
  display: ${({ display }) => (display ? 'block' : 'none')};
  margin-top: 12px;

  ${minBreakpointQuery.small`
    margin-top: 16px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 20px;
  `}

  p {
    ${fontSize(18)};
    line-height: 1.6;

    ${minBreakpointQuery.small`
      ${fontSize(20)};
    `}

    ${minBreakpointQuery.large`
      ${fontSize(22)};
    `}
  }
`;

const Accordion = ({ heading, items }) => {
  const [activeItem, setActiveItem] = useState(0);

  return (
    <StyledAccordion>
      <Container>
        <StyledHeading>{heading}</StyledHeading>
        {items.map(({ heading, text }, i) => {
          const display = activeItem === i;

          return (
            <StyledItem key={i}>
              <StyledSubHeading
                display={display}
                onClick={() => setActiveItem(display ? undefined : i)}
              >
                {heading}
              </StyledSubHeading>
              <StyledText html={text} display={display} />
            </StyledItem>
          );
        })}
      </Container>
    </StyledAccordion>
  );
};

export default Accordion;
