import React from 'react';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
  standardColours,
  brandColours,
} from '../styles';
import { Container, Heading, Button } from './ui';

const variableCtaStyles = version => {
  switch (version) {
    case 2:
      return css`
        background-color: ${brandColours.senary};
      `;
    case 3:
      return css`
        background-color: ${brandColours.quinary};
        color: ${standardColours.white};
      `;
    case 4:
      return css`
        background-color: ${brandColours.tertiary};
      `;
    default:
      return css`
        background-color: ${brandColours.primary};
        color: ${brandColours.quaternary};
      `;
  }
};

const StyledCtaStrip = styled.aside`
  ${sectionMargins('30px', '70px')};
`;

const StyledInner = styled.div`
  ${({ version }) => variableCtaStyles(version)};
  padding: 35px 40px;
  border-radius: 10px;

  ${minBreakpointQuery.small`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}

  ${minBreakpointQuery.medium`
    padding: 45px 50px;
  `}

  ${minBreakpointQuery.large`
    padding: 55px 60px;
  `}
`;

const StyledHeading = styled(Heading)`
  max-width: 500px;
`;

const StyledButton = styled(Button)`
  white-space: nowrap;

  ${maxBreakpointQuery.small`
    margin-top: 20px;
  `}

  ${minBreakpointQuery.small`
    margin-left: 20px;
  `}
`;

const CtaStrip = ({ heading, link, version }) => (
  <StyledCtaStrip>
    <Container>
      <StyledInner version={version}>
        <StyledHeading>{heading}</StyledHeading>
        <StyledButton
          {...(link.page
            ? {
                to: link.page,
              }
            : {
                href: link.url,
                target: '_blank',
                rel: 'noopener noreferrer',
              })}
          alt={version === 1}
        >
          {link.text}
        </StyledButton>
      </StyledInner>
    </Container>
  </StyledCtaStrip>
);

export default CtaStrip;
