import React, { useEffect, useRef, useState } from 'react';
import { InView } from 'react-intersection-observer';
import styled, { css } from 'styled-components';
import {
  brandColours,
  standardColours,
  visuallyHidden,
  minBreakpointQuery,
  fontSize,
} from '../../styles';
import { Button, Svg } from '.';
import playIcon from '../../images/play-icon.inline.svg';

const StyledVideo = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;

  ${({ ratio }) => {
    if (ratio) {
      return css`
        position: relative;
        height: 0;
        padding-bottom: ${ratio}%;
      `;
    }
  }};
`;

const StyledInternalintVideoElement = styled.video`
  height: auto;
  width: 100%;
  max-width: 100%;
  border-radius: 15px;
`;

const StyledPlayButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  background: none;
  border: none;
  transform: translate(-50%, -50%);

  svg {
    width: 15vw;
    max-width: 190px;
  }

  span {
    ${visuallyHidden()};
  }
`;

const StyledExternalintVideoElement = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`;

const StyledPlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${standardColours.black};
`;

const StyledPlaceholderImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 0.7;
`;

const StyledPlaceholderContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 80%;
  max-width: 500px;
  color: ${brandColours.septenary};
  background: ${brandColours.secondary};
  border-radius: 5px;
  padding: 20px;
`;

const StyledPlaceholderText = styled.p`
  ${minBreakpointQuery.smedium`
    ${fontSize(18)}; 
  `}

  span {
    display: block;
  }

  a {
    display: inline-block;
    margin-top: 4px;
    text-decoration: underline;
    text-underline-offset: 2px;
  }
`;

export const Video = ({
  data,
  controls = true,
  muted = true,
  autoPlay = false,
  playsInline = true,
  onTimeUpdate,
  activeTimestamp = 0,
  isVideoPlayer,
  ...props
}) => {
  const intVideoEl = useRef(null);
  const intVideoSrcEl = useRef(null);

  const [played, setPlayed] = useState(false);

  const getCurrentTime = () => {
    if (intVideoEl.current) {
      const currentTime = intVideoEl.current.currentTime;
      onTimeUpdate(currentTime);
    }
  };

  useEffect(() => {
    if (intVideoEl.current) {
      intVideoEl.current.currentTime = activeTimestamp;
      if (autoPlay) {
        intVideoSrcEl.current.src = intVideoSrcEl.current.dataset.src;
        if (activeTimestamp === 0) {
          intVideoEl.current.load();
        }
      }
    }
  }, [activeTimestamp, autoPlay, data]);

  const playVideo = () => {
    if (autoPlay) {
      intVideoSrcEl.current.src = intVideoSrcEl.current.dataset.src;
      intVideoEl.current.load();
    }
  };

  return (
    <InView triggerOnce={true} onChange={() => playVideo()} {...props}>
      {data.format ? (
        <StyledVideo>
          <StyledInternalintVideoElement
            ref={intVideoEl}
            onTimeUpdate={getCurrentTime}
            {...(!autoPlay && { preload: 'none' })}
            {...(!autoPlay &&
              data.video &&
              data.video.thumbnailUrl && { poster: data.video.thumbnailUrl })}
            controls={controls}
            muted={muted}
            autoPlay={autoPlay}
            playsInline={playsInline}
          >
            <source
              ref={intVideoSrcEl}
              {...(autoPlay
                ? { 'data-src': (data.video && data.video.mp4Url) || data.url }
                : { src: (data.video && data.video.mp4Url) || data.url })}
              type="video/mp4"
            />
          </StyledInternalintVideoElement>
          {!played && !isVideoPlayer && (
            <StyledPlayButton
              onClick={() => {
                intVideoEl.current.play();
                setPlayed(true);
              }}
            >
              <span>Play</span>
              <Svg image={playIcon} />
            </StyledPlayButton>
          )}
        </StyledVideo>
      ) : (
        <StyledVideo
          ratio={
            data.height && data.width ? (data.height / data.width) * 100 : 56.25
          }
          {...props}
        >
          <StyledExternalintVideoElement
            {...{
              [process.env.GATSBY_CONTEXT === 'production'
                ? 'data-cookieblock-src'
                : 'src']:
                data.provider === 'youtube'
                  ? `https://www.youtube-nocookie.com/embed/${data.providerUid}`
                  : data.provider === 'vimeo'
                  ? `https://player.vimeo.com/video/${data.providerUid}?dnt=true`
                  : `https://www.facebook.com/plugins/data.php?href=${data.url}`,
            }}
            {...(process.env.GATSBY_CONTEXT === 'production' && {
              'data-cookieconsent': 'marketing',
            })}
          ></StyledExternalintVideoElement>
          {process.env.GATSBY_CONTEXT === 'production' && (
            <StyledPlaceholder className="cookieconsent-optout-marketing">
              <StyledPlaceholderImage
                src={`https://img.youtube.com/vi/${data.providerUid}/hqdefault.jpg`}
                alt="Video placeholder"
              />
              <StyledPlaceholderContent>
                <StyledPlaceholderText>
                  <span>
                    This video is not playable as consent has not been given.
                  </span>{' '}
                  <a href="javascript:Cookiebot.renew()">Change your consent</a>
                </StyledPlaceholderText>
              </StyledPlaceholderContent>
            </StyledPlaceholder>
          )}
        </StyledVideo>
      )}
    </InView>
  );
};
