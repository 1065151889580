export const buildUrl = (slug, page) => {
  let url = '/';

  if (page) {
    switch (page.locale) {
      case 'en-GB':
        url += 'uk/';
        break;
      case 'nl-NL':
        url += 'nl/';
        break;
      case 'fr-FR':
        url += 'fr/';
        break;
      case 'de-DE':
        url += 'de/';
        break;
    }

    if (page.treeParent) {
      if (page.treeParent.treeParent && page.treeParent.treeParent.treeParent) {
        url += `${page.treeParent.treeParent.treeParent.slug}/`;
      }

      if (page.treeParent.treeParent) {
        url += `${page.treeParent.treeParent.slug}/`;
      }

      url += `${page.treeParent.slug}/`;
    } else if (page.internal && page.internal.type) {
      if (page.internal.type === 'DatoCmsCaseStudy') {
        url += 'resources/case-studies/';
      } else if (page.internal.type === 'DatoCmsNews') {
        url += 'resources/news/';
      } else if (page.internal.type === 'DatoCmsBlog') {
        url += 'resources/blog/';
      } else if (page.internal.type === 'DatoCmsReport') {
        url += 'resources/reports/';
      }
    }
  }

  url += `${slug !== '/' ? `${slug.replace(/^\/|\/$/g, '')}/` : ''}`;

  return url;
};
