import { css } from 'styled-components';
import { breakpointSizes, brandFonts, fluidFontSize } from '../../styles';

export const headingStyles = alt => {
  return css`
    font-family: ${brandFonts.secondary};
    ${fluidFontSize(
      alt ? '32px' : '25px',
      alt ? '45px' : '35px',
      breakpointSizes.tiny,
      breakpointSizes.xxxxlarge
    )};
  `;
};
