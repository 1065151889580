import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { Container, Heading, Link } from './ui';
import {
  brandColours,
  brandFonts,
  sectionPaddings,
  standardColours,
  fontSize,
  fontWeights,
  minBreakpointQuery,
  maxBreakpointQuery,
} from '../styles';
import { GatsbyImage } from 'gatsby-plugin-image';

const StyledFeaturedResources = styled.section`
  ${sectionPaddings()};
`;

const StyledHeader = styled.header`
  display: flex;
  justify-content: center;
`;

const StyledArticle = styled.article`
  background-color: ${brandColours.tertiary};
  position: relative;
  display: flex;
  min-height: 290px;
  border-radius: 15px;

  ${minBreakpointQuery.mlarge`
    ${({ layout }) => (layout === 'tall' ? 'grid-row: 1 / 3' : '')};
    ${({ layout }) => (layout === 'wide' ? 'grid-column: 2 / 4' : '')};
  `};

  &:nth-child(odd) {
    background-color: ${brandColours.quinary};
  }
`;

const StyledGrid = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  ${sectionPaddings('30px', '60px')};

  ${minBreakpointQuery.smedium`
    grid-template-columns: 1fr 1fr;
  `};

  ${minBreakpointQuery.mlarge`
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  `};
`;

const StyledImage = styled(GatsbyImage)`
  border-radius: 15px;

  ${maxBreakpointQuery.mlarge`
    max-height: 290px;
  `};
`;

const StyledMeta = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 45px 50px;
`;

const StyledRowMeta = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
`;

const StyledTag = styled.span`
  display: inline-block;
  padding: 4px 16px;
  background-color: ${({ alt }) =>
    alt ? brandColours.primary : brandColours.tertiary};
  border-radius: 14px;
  color: ${standardColours.white};
  font-weight: ${fontWeights.bold};
  align-self: start;
`;

const StyledTitle = styled.h3`
  font-family: ${brandFonts.secondary};
  ${fontSize(30)};
  color: ${({ alt }) => (alt ? brandColours.primary : brandColours.septenary)};
  margin-top: ${({ alt }) => (alt ? 0 : 'auto')};
`;

const StyledLink = styled(Link)`
  text-transform: uppercase;
  ${fontSize(14)};
  color: ${({ alt }) => (alt ? brandColours.primary : brandColours.septenary)};
  font-weight: ${fontWeights.bold};
  margin-top: ${({ alt }) => (alt ? 0 : '32px')};
`;

const StyledPublished = styled.time`
  font-weight: ${fontWeights.bold};
  color: ${({ alt }) => (alt ? brandColours.septenary : brandColours.primary)};
`;

const FeaturedResources = ({ heading }) => {
  const {
    datoCmsReport: report,
    datoCmsBlog: blog,
    datoCmsNews: article,
    datoCmsCaseStudy: caseStudy,
  } = useStaticQuery(graphql`
    query LatestResourcesQuery {
      datoCmsReport {
        ...LatestReportFragment
      }
      datoCmsBlog {
        ...LatestBlogFragment
      }
      datoCmsNews {
        ...LatestNewsFragment
      }
      datoCmsCaseStudy {
        ...LatestCaseStudyFragment
      }
    }
  `);

  return (
    <StyledFeaturedResources>
      <Container>
        <StyledHeader>
          <Heading alt>{heading}</Heading>
        </StyledHeader>
        <StyledGrid>
          <StyledArticle layout="tall">
            {report.banner && (
              <StyledImage
                alt={report.banner.image.alt}
                image={report.banner.image.gatsbyImageData}
              />
            )}
            <StyledMeta>
              <StyledTag>Report</StyledTag>
              <StyledTitle>{report.title}</StyledTitle>
              <StyledLink to={report.slug}>Read Report</StyledLink>
            </StyledMeta>
          </StyledArticle>
          <StyledArticle layout="wide">
            <StyledMeta>
              <StyledTitle alt>{blog.title}</StyledTitle>
              <StyledRowMeta>
                <StyledLink to={blog.slug} alt>
                  Read Blog Post
                </StyledLink>
                <StyledPublished>{blog.meta.firstPublishedAt}</StyledPublished>
              </StyledRowMeta>
            </StyledMeta>
          </StyledArticle>
          <StyledArticle>
            <StyledMeta>
              <StyledPublished alt>
                {article.meta.firstPublishedAt}
              </StyledPublished>
              <StyledTitle>{article.title}</StyledTitle>
              <StyledLink to={article.slug}>Read Article</StyledLink>
            </StyledMeta>
          </StyledArticle>
          <StyledArticle>
            <StyledImage
              alt={caseStudy.banner.image.alt}
              image={caseStudy.banner.image.gatsbyImageData}
            />
            <StyledMeta>
              <StyledTag alt>Case Study</StyledTag>
              <StyledTitle>{caseStudy.title}</StyledTitle>
              <StyledLink to={caseStudy.slug}>View Case Study</StyledLink>
            </StyledMeta>
          </StyledArticle>
        </StyledGrid>
      </Container>
    </StyledFeaturedResources>
  );
};

export const LatestReportFragment = graphql`
  fragment LatestReportFragment on DatoCmsReport {
    title
    slug
    banner {
      image {
        gatsbyImageData(width: 760, height: 1140)
        alt
      }
    }
  }
`;

export const LatestBlogFragment = graphql`
  fragment LatestBlogFragment on DatoCmsBlog {
    title
    slug
    meta {
      firstPublishedAt(formatString: "D MMM YYYY")
    }
  }
`;

export const LatestNewsFragment = graphql`
  fragment LatestNewsFragment on DatoCmsNews {
    title
    slug
    meta {
      firstPublishedAt(formatString: "D MMM YYYY")
    }
  }
`;

export const LatestCaseStudyFragment = graphql`
  fragment LatestCaseStudyFragment on DatoCmsCaseStudy {
    title
    slug
    banner {
      image {
        gatsbyImageData(width: 760, height: 580)
        alt
      }
    }
  }
`;

export default FeaturedResources;
