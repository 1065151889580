import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  position: relative;
`;

export const Wrapper = ({ children, ...props }) => (
  <StyledWrapper {...props}>{children}</StyledWrapper>
);
