import React from 'react';
import styled from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
} from '../styles';
import { Container, Heading, Slider, Button } from './ui';

const StyledCardSlider = styled.section`
  ${sectionMargins()};
  overflow: hidden;
`;

const StyledHeader = styled.header`
  margin-bottom: 30px;

  ${minBreakpointQuery.small`
    margin-bottom: 35px;
  `}

  ${minBreakpointQuery.smedium`
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 40px;
  `}
`;

const StyledButton = styled(Button)`
  white-space: nowrap;

  ${maxBreakpointQuery.smedium`
    margin-top: 15px;
  `}
`;

const StyledItems = styled(Slider)`
  .slick-arrow {
    top: 50%;

    &.slick-prev {
      left: 0;
      transform: translate(-25px, -50%);

      ${minBreakpointQuery.xlarge`
        transform: translate(-50%, -50%);
      `}
    }

    &.slick-next {
      right: 0;
      transform: translate(25px, -50%);

      ${minBreakpointQuery.xlarge`
        transform: translate(calc(50% + 10px), -50%);
      `}
    }
  }

  .slick-list {
    margin: 0 -10px;

    ${minBreakpointQuery.xlarge`
      overflow: visible;
      clip-path: inset(0 -100vw 0 0);
    `}
  }

  .slick-slide {
    padding: 0 10px;
  }
`;

const CardSlider = ({ heading, link, children }) => {
  const sliderOptions = {
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: breakpointSizes.medium - 1,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: breakpointSizes.tsmall - 1,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <StyledCardSlider>
      <Container>
        <StyledHeader>
          <Heading alt={true}>{heading}</Heading>
          {link && <StyledButton to={link.page}>{link.text}</StyledButton>}
        </StyledHeader>
        <StyledItems sliderOptions={sliderOptions}>{children}</StyledItems>
      </Container>
    </StyledCardSlider>
  );
};

export default CardSlider;
