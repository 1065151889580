export const standardColours = {
  black: '#000',
  white: '#FFF',
  transparentBlack: value => {
    return `rgba(0, 0, 0, ${value})`;
  },
  transparentWhite: value => {
    return `rgba(255, 255, 255, ${value})`;
  },
  transparent: 'rgba(255, 255, 255, 0)',
};

export const brandColours = {
  primary: '#114948', // dark green
  secondary: '#c74e19', // dark orange
  tertiary: '#ff9a52', // light orange
  quaternary: '#d5f1c8', // light green
  quinary: '#ff4c48', // red
  senary: '#ffa89a', // pink
  septenary: '#f1ebda', // beige
  octonary: '#00666a', // green
  nonary: '#DFD8C3', // sand
};

export const elementColours = {
  textColour: brandColours.primary,
};
