import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandColours,
  fontSize,
  zIndexLayers,
} from '../styles';
import LocaleSwitcher from './LocaleSwitcher';
import { slugify } from '../utils';

const StyledGeolocationQuestion = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${zIndexLayers.sixth};
  display: flex;
  background-color: ${standardColours.transparentBlack(0.5)};
`;

const StyledInner = styled.div`
  margin: auto;
  padding: 30px;
  width: calc(100% - 60px);
  max-width: 480px;
  text-align: center;
  background-color: ${brandColours.nonary};
  border-radius: 15px;

  ${minBreakpointQuery.small`
    padding: 40px;
  `}

  ${minBreakpointQuery.large`
    padding: 50px;
  `}
`;

const StyledHeading = styled.h3`
  ${fontSize(20)};

  ${minBreakpointQuery.small`
    ${fontSize(23)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(26)};
  `}
`;

const StyledText = styled.p`
  margin-top: 12px;
  line-height: 1.7;

  ${minBreakpointQuery.small`
    margin-top: 16px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 20px;
  `}

  ${({ alt }) => {
    if (alt) {
      return css`
        ${fontSize(13)};
      `;
    }
  }}
`;

const StyledLocaleSwitcher = styled(LocaleSwitcher)`
  flex-wrap: wrap;
  margin-top: 16px;

  ${minBreakpointQuery.small`
    margin-top: 20px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 24px;
  `}
`;

const GeolocationQuestion = ({ locale }) => {
  const {
    datoCmsSite: { name },
  } = useStaticQuery(graphql`
    query GeolocationQuestionQuery {
      datoCmsSite {
        name
      }
    }
  `);

  const localStorageKey = `${slugify(name)}:locale`;

  const [country, setCountry] = useState();

  useEffect(() => {
    if (
      !localStorage.getItem(localStorageKey) &&
      !navigator.userAgent.match(/bot|spider/i)
    ) {
      fetch(`https://ipinfo.io/json?token=${process.env.GATSBY_IPINFO_TOKEN}`)
        .then(res => res.json())
        .then(data => {
          setCountry(data.country);
        });
    }
  }, [localStorageKey]);

  return (
    locale === 'en-US' &&
    country === 'GB' && (
      <StyledGeolocationQuestion>
        <StyledInner>
          <StyledHeading>Are you in the right place?</StyledHeading>
          <StyledText>
            You’re viewing the {locale === 'en-US' ? 'US' : 'UK'} site but we
            noticed you’re in the {locale !== 'en-US' ? 'US' : 'UK'}, would you
            like to switch to our {locale !== 'en-US' ? 'US' : 'UK'} version?
          </StyledText>
          <StyledLocaleSwitcher locale={locale} isGeolocationQuestion={true} />
        </StyledInner>
      </StyledGeolocationQuestion>
    )
  );
};

export default GeolocationQuestion;
