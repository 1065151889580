import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  standardColours,
  brandColours,
  fontSize,
} from '../styles';
import { BlobBackground, Container, Heading, HtmlContent, Button } from './ui';

const StyledContentBlocks = styled.section`
  ${sectionMargins()};
  color: ${standardColours.white};
`;

const StyledItems = styled.div`
  display: grid;
  gap: 30px;
  margin: 30px 0;

  ${minBreakpointQuery.small`
    gap: 40px;
    margin-top: 40px;
    margin-bottom: 40px;
  `}

  ${minBreakpointQuery.medium`
    grid-template-columns: repeat(2, 1fr);
    column-gap: 80px;
  `}

  ${minBreakpointQuery.mlarge`
    column-gap: 120px;
  `}
  
  ${minBreakpointQuery.large`
    gap: 50px 160px;
    margin-top: 50px;
    margin-bottom: 50px;
  `}

  ${minBreakpointQuery.xxlarge`
    column-gap: 200px;
  `}
`;

const StyledItem = styled.article``;

const StyledHeading = styled.h3`
  margin-bottom: 16px;
  ${fontSize(19)};

  ${minBreakpointQuery.small`
    margin-bottom: 18px;
    ${fontSize(22)};
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 20px;
    ${fontSize(25)};
  `}
`;

const StyledText = styled(HtmlContent)``;

const ContentBlocks = ({ heading, items, link }) => (
  <StyledContentBlocks>
    <BlobBackground colour={brandColours.secondary}>
      <Container>
        <Heading alt={true}>{heading}</Heading>
        <StyledItems>
          {items.map(({ id, heading, text }) => (
            <StyledItem key={id}>
              <StyledHeading>{heading}</StyledHeading>
              <StyledText html={text} />
            </StyledItem>
          ))}
        </StyledItems>
        {link && <Button to={link.page}>{link.text}</Button>}
      </Container>
    </BlobBackground>
  </StyledContentBlocks>
);

export default ContentBlocks;
