import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  brandColours,
  brandFonts,
  fontSize,
  fontWeights,
  standardTransition,
  visuallyHidden,
} from '../styles';
import { Svg, Container, Link } from './ui';
import NewsletterSignUp from './NewsletterSignUp';
import Logo from './Logo';
import { getSingleLocaleFields } from '../utils';
import footerTopShape from '../images/footer-top-shape.inline.svg';
import footerNewsletterBg from '../images/footer-newsletter-bg.svg';
import arrow from '../images/arrow.inline.svg';
import linkedinIcon from '../images/linkedin-icon.inline.svg';
import instagramIcon from '../images/instagram-icon.inline.svg';
import twitterXIcon from '../images/twitter-x-icon.inline.svg';
import facebookIcon from '../images/facebook-icon.inline.svg';

const StyledFooter = styled.footer`
  margin-top: 40px;
  padding-top: calc(100px - (100vw / 11));
  overflow: hidden;

  ${minBreakpointQuery.mlarge`
    padding-top: calc(130px - (100vw / 11));
  `}

  ${minBreakpointQuery.large`
    padding-top: calc(160px - (100vw / 11));
  `}
`;

const StyledTopShape = styled(Svg)`
  position: relative;
  right: -5px;
  bottom: -5px;
  left: -5px;
  display: block;
  height: auto;
  width: calc(100% + 10px);
  fill: ${brandColours.primary};
`;

const StyledOuter = styled.div`
  background-color: ${brandColours.primary};
`;

const StyledNewsletterAccreditations = styled.div`
  display: grid;
  gap: 40px;
  margin-bottom: 40px;

  ${minBreakpointQuery.medium`
    display: grid;
    grid-template-columns: 1.6fr 1fr;
  `}

  ${minBreakpointQuery.mlarge`
    grid-template-columns: 1.4fr 1fr;
  `}

  ${minBreakpointQuery.large`
    grid-template-columns: 1.2fr 1fr;
    gap: 60px;
  `}
`;

const StyledNewsletter = styled(NewsletterSignUp)`
  position: relative;
  margin: -100px 0 0 -100px;
  padding: 100px 120px 50px 100px;
  background: url('${footerNewsletterBg}') no-repeat 50% 50% / contain;

  ${maxBreakpointQuery.medium`
    margin-right: -100px;
  `}

  ${minBreakpointQuery.small`
    padding: 120px 140px 60px 100px;
  `}

  ${minBreakpointQuery.medium`
    margin-left: -100px;
  `}

  ${minBreakpointQuery.mlarge`
    margin-top: -130px;
    margin-left: -110px;
    padding: 150px 170px 80px 120px;
  `}

  ${minBreakpointQuery.large`
    margin-top: -160px;
    margin-left: -140px;
    padding: 180px 200px 100px 140px;
  `}
`;

const StyledAccreditations = styled.div``;

const StyledHeading = styled.h3`
  margin: 0 20px 20px 0;
  color: ${brandColours.quaternary};
  font-family: ${brandFonts.secondary};
  ${fontSize(24)};
  text-align: right;

  ${minBreakpointQuery.small`
    ${fontSize(27)};
  `}

  ${minBreakpointQuery.large`
    margin-right: 30px;
    margin-bottom: 30px;
    ${fontSize(30)};
  `}
`;

const StyledArrow = styled(Svg)`
  display: inline-block;
  vertical-align: top;
  margin: 10px 15px 0 0;
  height: 40px;
  width: 40px;
  stroke: ${brandColours.quaternary};

  ${minBreakpointQuery.large`
    margin-top: 12px;
    margin-right: 20px;
    height: 50px;
    width: 50px;
  `}
`;

const StyledBadges = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
`;

const StyledBadge = styled.img`
  margin: 5px;
  max-height: 70px;
  max-width: 100px;
`;

const StyledLogoLinks = styled.div`
  color: ${brandColours.quaternary};

  ${maxBreakpointQuery.medium`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px 20px;
    padding-bottom: 40px;
  `}

  ${minBreakpointQuery.medium`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;

const StyledLogo = styled(Logo)`
  width: 60px;

  ${minBreakpointQuery.small`
    width: 70px;
  `}

  ${minBreakpointQuery.medium`
    position: relative;
    top: 1px;
    align-self: end;
    padding-right: 30px;
    width: 90px;
    background-color: ${brandColours.primary};
  `}

  ${minBreakpointQuery.large`
    width: 100px;
  `}
`;

const StyledLinks = styled.nav`
  ${maxBreakpointQuery.medium`
    display: flex;
    flex-direction: column;
    align-items: end;
    text-align: right;
  `}

  ${minBreakpointQuery.medium`
    margin-top: 15px;
    margin-right: auto;
    margin-bottom: 15px;
  `}
`;

const StyledLink = styled(Link)`
  padding: 2px;
  ${fontSize(14)};
  text-decoration: underline;

  ${maxBreakpointQuery.medium`
    margin-top: 4px;
  `}

  ${minBreakpointQuery.medium`
    margin-right: 4px;
  `}

  &:first-child {
    ${maxBreakpointQuery.medium`
      margin-top: 0;
    `}
  }

  &:last-child {
    ${minBreakpointQuery.medium`
      margin-right: 0;
    `}
  }
`;

const StyledSocial = styled.div`
  ${maxBreakpointQuery.medium`
    grid-column: 1/3;
    text-align: center;
  `}

  ${minBreakpointQuery.small`
    display: flex;
    justify-content: center;
    align-items: center;
  `}

  ${minBreakpointQuery.medium`
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 20px;
  `}
`;

const StyledSocialText = styled.p`
  font-weight: ${fontWeights.bold};
  ${fontSize(14)};
  line-height: 1.5;
  letter-spacing: 0.88px;

  ${maxBreakpointQuery.small`
    margin-bottom: 20px;
  `}

  ${minBreakpointQuery.small`
    margin-right: 20px;
  `}
`;

const StyledSocialLinks = styled.div`
  white-space: nowrap;
`;

const StyledSocialIcon = styled(Svg)`
  height: 16px;
  width: 16px;
  fill: ${brandColours.quaternary};
  transition: ${standardTransition('fill')};
`;

const StyledSocialLink = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  height: 48px;
  width: 48px;
  border: 1px solid ${standardColours.transparentWhite(0.2)};
  border-radius: 50%;
  transition: ${standardTransition('background-color')};

  span {
    ${visuallyHidden()};
  }

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    background-color: ${brandColours.quaternary};

    ${StyledSocialIcon} {
      fill: ${brandColours.primary};
    }
  }
`;

const StyledMiddle = styled.div`
  padding: 20px 0;
  color: ${brandColours.quaternary};
  border-top: 1px solid ${brandColours.quaternary};
  border-bottom: 1px solid ${brandColours.quaternary};

  ${minBreakpointQuery.small`
    padding-top: 30px;
    padding-bottom: 30px;
  `}

  ${minBreakpointQuery.large`
    padding-top: 40px;
    padding-bottom: 40px;
  `}
`;

const StyledText = styled.p`
  margin: 10px 0;
  ${fontSize(14)};
  line-height: 2;

  ${({ alt }) => {
    if (alt) {
      return css`
        color: ${brandColours.septenary};
      `;
    }
  }}

  a {
    display: inline-block;
    text-decoration: underline;
  }
`;

const StyledCopyright = styled.div`
  padding: 10px 0;

  ${maxBreakpointQuery.medium`
    text-align: center;
  `}

  ${minBreakpointQuery.small`
    padding-top: 20px;
    padding-bottom: 20px;
  `}

  ${minBreakpointQuery.medium`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}

  ${minBreakpointQuery.large`
    padding-top: 30px;
    padding-bottom: 30px;
  `}
`;

const Footer = ({ locale }) => {
  const { allDatoCmsFooter, allDatoCmsContactInformation } =
    useStaticQuery(graphql`
      query FooterQuery {
        allDatoCmsFooter {
          nodes {
            locale
            accreditations {
              url
              alt
            }
            links {
              ... on DatoCmsLink {
                id
                text
                page {
                  ...LinkFragment
                }
              }
              ... on DatoCmsExternalLink {
                id
                text
                url
              }
            }
            text
            copyrightText
            pdfs {
              title
              url
            }
          }
        }
        allDatoCmsContactInformation {
          nodes {
            locale
            linkedinProfile
            instagramProfile
            twitterXProfile
            facebookProfile
          }
        }
      }
    `);

  const { accreditations, links, text, copyrightText, pdfs } =
    getSingleLocaleFields(allDatoCmsFooter, locale);

  const {
    linkedinProfile,
    instagramProfile,
    twitterXProfile,
    facebookProfile,
  } = getSingleLocaleFields(allDatoCmsContactInformation, locale);

  return (
    <StyledFooter>
      <StyledTopShape image={footerTopShape} />
      <StyledOuter>
        <Container>
          <StyledNewsletterAccreditations>
            <StyledNewsletter locale={locale} />
            <StyledAccreditations>
              <StyledHeading>
                <StyledArrow image={arrow} />
                Accreditations
              </StyledHeading>
              <StyledBadges>
                {accreditations.map(({ url, alt }) => (
                  <StyledBadge src={url} alt={alt} loading="lazy" />
                ))}
              </StyledBadges>
            </StyledAccreditations>
          </StyledNewsletterAccreditations>
          <StyledLogoLinks>
            <StyledLogo locale={locale} alt={true} />
            <StyledLinks>
              {links.map(link => (
                <StyledLink
                  key={link.id}
                  {...(link.page
                    ? {
                        to: link.page,
                      }
                    : {
                        href: link.url,
                        target: '_blank',
                        rel: 'noopener noreferrer',
                      })}
                >
                  {link.text}
                </StyledLink>
              ))}
            </StyledLinks>
            {(linkedinProfile ||
              instagramProfile ||
              twitterXProfile ||
              facebookProfile) && (
              <StyledSocial>
                <StyledSocialText>Follow us around the world</StyledSocialText>
                <StyledSocialLinks>
                  {linkedinProfile && (
                    <StyledSocialLink
                      href={linkedinProfile}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <StyledSocialIcon image={linkedinIcon} />
                      <span>Linkedin</span>
                    </StyledSocialLink>
                  )}
                  {instagramProfile && (
                    <StyledSocialLink
                      href={instagramProfile}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <StyledSocialIcon image={instagramIcon} />
                      <span>Instagram</span>
                    </StyledSocialLink>
                  )}
                  {twitterXProfile && (
                    <StyledSocialLink
                      href={twitterXProfile}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <StyledSocialIcon image={twitterXIcon} />
                      <span>Twitter / X</span>
                    </StyledSocialLink>
                  )}
                  {facebookProfile && (
                    <StyledSocialLink
                      href={facebookProfile}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <StyledSocialIcon image={facebookIcon} />
                      <span>Facebook</span>
                    </StyledSocialLink>
                  )}
                </StyledSocialLinks>
              </StyledSocial>
            )}
          </StyledLogoLinks>
          <StyledMiddle>
            <StyledText>{text}</StyledText>
          </StyledMiddle>
          <StyledCopyright>
            <StyledText alt={true}>
              &copy; {new Date().getFullYear()} {copyrightText}{' '}
              {pdfs &&
                pdfs.length > 0 &&
                pdfs.map(({ title, url }, id) => (
                  <React.Fragment key={id}>
                    {' '}
                    <a
                      href={url}
                      target="_blank"
                      rel="noreferrer noopener"
                      download
                    >
                      {title}
                    </a>
                  </React.Fragment>
                ))}
            </StyledText>
            <StyledText alt={true}>
              <a
                href="https://www.attachdigital.co.uk"
                target="_blank"
                rel="noopener"
              >
                Website by Attach Digital
              </a>
            </StyledText>
          </StyledCopyright>
        </Container>
      </StyledOuter>
    </StyledFooter>
  );
};

export default Footer;
