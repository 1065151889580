import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  brandColours,
  brandFonts,
  fontWeights,
  fontSize,
} from '../styles';
import { Container, Heading, HtmlContent } from './ui';

const StyledAnchoredContentBlocks = styled.section`
  ${sectionMargins()};
`;

const StyledHeader = styled.header`
  margin-bottom: 30px;

  ${minBreakpointQuery.small`
    margin-bottom: 40px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 50px;
  `}
`;

const StyledHeading = styled(Heading)``;

const StyledText = styled(HtmlContent)`
  margin-top: 20px;

  ${minBreakpointQuery.small`
    margin-top: 25px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 30px;
  `}
`;

const StyledInner = styled.div`
  display: grid;
  gap: 30px;

  ${minBreakpointQuery.small`
    gap: 40px;
  `}

  ${minBreakpointQuery.smedium`
    grid-template-columns: 1fr 3fr;
    align-items: start;
  `}

  ${minBreakpointQuery.medium`
    gap: 60px;
  `}

  ${minBreakpointQuery.large`
    gap: 80px;
  `}
`;

const StyledList = styled.ul`
  ${minBreakpointQuery.smedium`
    position: sticky;
    top: 20px;
  `}
`;

const StyledItem = styled.li`
  border-top: 1px solid ${brandColours.primary};

  &:first-child {
    border-top: none;
  }
`;

const StyledLink = styled.button`
  padding: 15px 0;
  font-family: ${brandFonts.secondary};
  font-weight: ${fontWeights.bold};
  ${fontSize(17)};
  line-height: 1.5;
  text-align: left;
  background: none;
  border: none;

  ${minBreakpointQuery.small`
    ${fontSize(19)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(21)};
  `}
`;

const StyledContentBlocks = styled.div``;

const StyledContentBlock = styled.div`
  margin-top: 10px;
  padding: 10px 0;

  ${minBreakpointQuery.small`
    margin-top: 20px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 30px;
  `}

  &:first-child {
    margin-top: 0;
  }
`;

const StyledContentHeading = styled.h3`
  font-family: ${brandFonts.secondary};
  ${fontSize(19)};

  ${minBreakpointQuery.small`
    ${fontSize(22)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(25)};
  `}
`;

const StyledContent = styled(HtmlContent)`
  margin-top: 20px;

  ${minBreakpointQuery.small`
    margin-top: 24px;
  `}
`;

export const AnchoredContentBlocks = ({ heading, text, items }) => (
  <StyledAnchoredContentBlocks>
    <Container>
      <StyledHeader>
        <StyledHeading>{heading}</StyledHeading>
        {text && <StyledText html={text} />}
      </StyledHeader>
      <StyledInner>
        <StyledList>
          {items.map(({ id, anchorLinkText }) => (
            <StyledItem key={id}>
              <StyledLink
                onClick={() =>
                  document
                    .getElementById(`anchored-content-block-${id}`)
                    .scrollIntoView({
                      behavior: 'smooth',
                    })
                }
              >
                {anchorLinkText}
              </StyledLink>
            </StyledItem>
          ))}
        </StyledList>
        <StyledContentBlocks>
          {items.map(({ id, heading, content }) => (
            <StyledContentBlock key={id} id={`anchored-content-block-${id}`}>
              <StyledContentHeading>{heading}</StyledContentHeading>
              <StyledContent html={content} />
            </StyledContentBlock>
          ))}
        </StyledContentBlocks>
      </StyledInner>
    </Container>
  </StyledAnchoredContentBlocks>
);

export default AnchoredContentBlocks;
