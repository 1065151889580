export const getCollectionLocaleFields = (object, locale) => {
  return object.group.find(({ fieldValue }) => fieldValue === locale);
};

export const getSingleLocaleFields = (object, locale) => {
  return object.nodes.find(node => node.locale === locale);
};

export const getSlugLocalesWithArchives = (slugLocales, archives) =>
  slugLocales.map(slugLocale => {
    const archive = archives.nodes.find(
      ({ locale }) => locale === slugLocale.locale
    );

    return {
      locale: slugLocale.locale,
      value: `${archive.slug}/${slugLocale.value}`,
    };
  });
