import React, { useState, useEffect } from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import styled from 'styled-components';
import { sectionMargins } from '../styles';
import { Container } from './ui';

const StyledVisaWidget = styled.section`
  ${sectionMargins()};
`;

const VisaWidget = () => {
  const [partnerKey, setPartnerKey] = useState();

  useEffect(() => {
    setPartnerKey(`${process.env.GATSBY_VISA_WIDGET_PARTNER_KEY}`);
  }, []);

  return (
    <StyledVisaWidget>
      <HelmetDatoCms>
        <script
          src="https://cdn.cibt.com/widget/js/widget.js"
          type="text/javascript"
        ></script>
      </HelmetDatoCms>
      <Container>
        {partnerKey && (
          <div
            id="cibt-travel-widget"
            data-key={partnerKey}
            data-purpose="B"
          ></div>
        )}
      </Container>
    </StyledVisaWidget>
  );
};

export default VisaWidget;
