import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
  standardColours,
  brandColours,
  brandFonts,
  fontWeights,
  fontSize,
} from '../styles';
import { Container, Heading, Button } from './ui';

const StyledContactCtaStrip = styled.aside`
  ${sectionMargins('30px', '70px')};
`;

const StyledInner = styled.div`
  padding: 40px;
  color: ${standardColours.white};
  background-color: ${brandColours.quinary};
  border-radius: 10px;

  ${minBreakpointQuery.small`
    padding: 50px;
  `}

  ${minBreakpointQuery.medium`
    display: flex;
    justify-content: space-between;
    align-items: end;
  `}

  ${minBreakpointQuery.large`
    padding: 60px;
  `}

  ${minBreakpointQuery.xxlarge`
    padding-top: 70px;
    padding-bottom: 70px;
  `}
`;

const StyledContent = styled.div``;

const StyledText = styled.p`
  margin-top: 10px;
  line-height: 1.5;
`;

const StyledButtons = styled.div`
  ${maxBreakpointQuery.medium`
    margin-top: 10px;
  `}

  ${minBreakpointQuery.medium`
    margin-left: 20px;
    white-space: nowrap;
  `}

  ${minBreakpointQuery.large`
    margin-left: 30px;
  `}
`;

const StyledButton = styled(Button)`
  white-space: nowrap;

  ${maxBreakpointQuery.medium`
    margin-top: 10px;
  `}

  ${maxBreakpointQuery.small`
    padding-right: 30px;
    padding-left: 30px;
  `}

  ${maxBreakpointQuery.tiny`
    padding-right: 20px;
    padding-left: 20px;
  `}
`;

const StyledSeparator = styled.span`
  margin: 0 12px;
  font-family: ${brandFonts.secondary};
  font-weight: ${fontWeights.bold};
  ${fontSize(18)};
  text-transform: uppercase;

  ${minBreakpointQuery.large`
    margin-right: 16px;
    margin-left: 16px;
    ${fontSize(20)};
  `}
`;

const ContactCtaStrip = ({ heading, text, phoneNumber, emailAddress }) => (
  <StyledContactCtaStrip>
    <Container>
      <StyledInner>
        <StyledContent>
          <Heading alt={true}>{heading}</Heading>
          {text && <StyledText>{text}</StyledText>}
        </StyledContent>
        <StyledButtons>
          <StyledButton href={`tel:${phoneNumber}`}>
            Call: {phoneNumber}
          </StyledButton>
          {emailAddress && (
            <>
              <StyledSeparator>or</StyledSeparator>
              <StyledButton href={`mailto:${emailAddress}`}>
                Email us
              </StyledButton>
            </>
          )}
        </StyledButtons>
      </StyledInner>
    </Container>
  </StyledContactCtaStrip>
);

export default ContactCtaStrip;
