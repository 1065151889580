import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  brandColours,
  fontWeights,
  fontSize,
  zIndexLayers,
  visuallyHidden,
} from '../styles';
import { Container, Svg, Button } from './ui';
import SitewideNotice from './SitewideNotice';
import Logo from './Logo';
import LocaleSwitcher from './LocaleSwitcher';
import Navigation from './Navigation';
import { getSingleLocaleFields } from '../utils';
import accountIcon from '../images/account-icon.inline.svg';

const StyledHeader = styled.header`
  ${({ displayNav }) => {
    if (!displayNav) {
      return css`
        ${maxBreakpointQuery.mlarge`
          position: sticky;
          top: 0;
          z-index: ${zIndexLayers.fourth};
          background-color: ${brandColours.septenary};
        `}
      `;
    }
  }}
`;

const StyledInner = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 14px;

  ${maxBreakpointQuery.mlarge`
    position: relative;
    margin-right: -30px;
    margin-left: -30px;
    border-bottom: 1px solid ${brandColours.nonary};
  `}

  ${minBreakpointQuery.tiny`
    gap: 20px;
  `}

  ${minBreakpointQuery.mlarge`
    align-items: flex-start;
  `}
`;

const StyledLogo = styled(Logo)`
  width: 60px;

  ${minBreakpointQuery.tsmall`
    width: 70px;
  `}

  ${minBreakpointQuery.small`
    width: 80px;
  `}

  ${minBreakpointQuery.mlarge`
    order: 1;
    margin-top: 10px;
    width: 70px;
  `}

  ${minBreakpointQuery.large`
    width: 80px;
  `}

  ${minBreakpointQuery.xlarge`
    width: 100px;
  `}
`;

const StyledButton = styled(Button)`
  text-transform: uppercase;

  ${maxBreakpointQuery.mlarge`
    align-self: center;
    margin-left: auto;
    padding: 12px 30px;
    ${fontSize(14)};
  `}

  ${maxBreakpointQuery.small`
    padding: 8px 20px;
    ${fontSize(12)};
  `}

  ${maxBreakpointQuery.tsmall`
    padding: 6px 15px;
    ${fontSize(11)};
  `}

  ${minBreakpointQuery.tsmall`
    padding-top: 10px;
    padding-bottom: 10px;
  `}

  ${minBreakpointQuery.mlarge`
    order: 3;
    margin-top: 15px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 20px;
  `}
`;

const StyledGroup = styled.div`
  display: flex;

  ${maxBreakpointQuery.mlarge`
    align-items: center;
    gap: 20px;
  `}

  ${maxBreakpointQuery.tiny`
    gap: 14px;
  `}

  ${minBreakpointQuery.mlarge`
    order: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: auto;
  `}
`;

const StyledOptions = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  ${minBreakpointQuery.tiny`
    gap: 12px;
  `}

  ${minBreakpointQuery.small`
    gap: 14px;
  `}

  ${minBreakpointQuery.mlarge`
    padding: 8px 12px;
    background-color: ${brandColours.nonary};
  `}
`;

const StyledPhone = styled.address`
  ${maxBreakpointQuery.mlarge`
    ${visuallyHidden()};
  `}

  ${minBreakpointQuery.mlarge`
    font-weight: ${fontWeights.bold};
    font-style: normal;
    ${fontSize(14)};
  `}

  span {
    ${minBreakpointQuery.mlarge`
      font-weight: ${fontWeights.regular};
      text-transform: uppercase;
    `}
  }
`;

const StyledPortalLink = styled.a`
  display: flex;
  align-items: center;
  gap: 6px;
  ${fontSize(12)};
  text-transform: uppercase;

  svg {
    fill: ${brandColours.primary};
    height: 20px;
    width: 20px;

    ${minBreakpointQuery.tsmall`
      height: 22px;
      width: 22px;
    `}

    ${minBreakpointQuery.smedium`
      height: 24px;
      width: 24px;
    `}

    ${minBreakpointQuery.mlarge`
      height: 18px;
      width: 18px;
    `}
  }

  span {
    ${maxBreakpointQuery.small`
      ${visuallyHidden()};
    `}

    ${minBreakpointQuery.small`
      margin-top: 4px;
    `}
  }
`;

const StyledNavigation = styled(Navigation)`
  ${maxBreakpointQuery.mlarge`
    height: 100%;
  `}

  ${minBreakpointQuery.mlarge`
    margin-top: 10px;
  `}
`;

const Header = ({ locale }) => {
  const { allDatoCmsContactInformation, allDatoCmsHeader } =
    useStaticQuery(graphql`
      query HeaderQuery {
        allDatoCmsContactInformation {
          nodes {
            locale
            phoneNumber
          }
        }
        allDatoCmsHeader {
          nodes {
            locale
            contactLink {
              text
              page {
                ...LinkFragment
              }
            }
            portalUrl
          }
        }
      }
    `);

  const { phoneNumber } = getSingleLocaleFields(
    allDatoCmsContactInformation,
    locale
  );

  const { contactLink, portalUrl } = getSingleLocaleFields(
    allDatoCmsHeader,
    locale
  );

  const [displayNav, setDisplayNav] = useState(false);

  return (
    <StyledHeader displayNav={displayNav}>
      <SitewideNotice locale={locale} />
      <Container>
        <StyledInner>
          <StyledLogo locale={locale} />
          <StyledButton to={contactLink.page}>{contactLink.text}</StyledButton>
          <StyledGroup>
            <StyledOptions>
              <StyledPhone>
                <span>Phone:</span> {phoneNumber}
              </StyledPhone>
              <LocaleSwitcher locale={locale} />
              {portalUrl && (
                <StyledPortalLink
                  href={portalUrl}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Svg image={accountIcon} />
                  <span>Login</span>
                </StyledPortalLink>
              )}
            </StyledOptions>
            <StyledNavigation
              locale={locale}
              displayNav={displayNav}
              setDisplayNav={setDisplayNav}
            />
          </StyledGroup>
        </StyledInner>
      </Container>
    </StyledHeader>
  );
};

export default Header;
