import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import {
  brandColours,
  fontSize,
  minBreakpointQuery,
  sectionMargins,
} from '../styles';
import { Container, Heading, Svg } from './ui';
import blobShape from '../images/blob-shape.inline.svg';
import appleAppStore from '../images/apple-app-store.svg';
import googlePlayStore from '../images/google-play-store.svg';

const StyledDiversityTravelApp = styled.section`
  ${({ reduceGaps }) =>
    reduceGaps ? sectionMargins('30px', '50px') : sectionMargins()};
`;

const StyledHeader = styled.header`
  text-align: center;
  margin-bottom: 30px;

  ${minBreakpointQuery.small`
    margin-bottom: 35px;
  `}

  ${minBreakpointQuery.medium`
    margin-bottom: 40px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 45px;
  `}
  
  ${minBreakpointQuery.xlarge`
    margin-bottom: 50px;
  `}
`;

const StyledText = styled.p`
  margin-top: 30px;
  line-height: 1.7;

  ${minBreakpointQuery.small`
    margin-top: 35px;
  `}

  ${minBreakpointQuery.medium`
    margin-top: 40px;
  `}
  
  ${minBreakpointQuery.large`
    margin-top: 45px;
  `}
  
  ${minBreakpointQuery.xlarge`
    margin-top: 50px;
  `}
`;

const StyledLinks = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  ${minBreakpointQuery.small`
    margin-top: 25px;
  `}

  ${minBreakpointQuery.medium`
    margin-top: 30px;
  `}
  
  ${minBreakpointQuery.large`
    margin-top: 35px;
  `}
  
  ${minBreakpointQuery.xlarge`
    margin-top: 40px;
  `}
`;

const StyledItems = styled.div`
  display: grid;
  gap: 30px;

  ${minBreakpointQuery.small`
    column-gap: 40px;
    row-gap: 45px;
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.smedium`
    row-gap: 60px;
  `}

  ${minBreakpointQuery.medium`
    grid-template-columns: 0.3fr 0.4fr 0.3fr;
  `}

  ${minBreakpointQuery.large`
    row-gap: 75px;
  `}

  ${minBreakpointQuery.xlarge`
    column-gap: 50px;
    row-gap: 90px;
  `}
`;

const StyledImageWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;

  ${minBreakpointQuery.small`
    grid-area: 1/1/1/3;
  `}

  ${minBreakpointQuery.medium`
    grid-area: 1/2/4/2;
  `}
`;

const StyledImage = styled(GatsbyImage)`
  grid-area: 1/1/1/1;
  max-width: 80%;
`;

const StyledBlobShape = styled(Svg)`
  width: 100%;
  height: 100%;
  grid-area: 1/1/1/1;

  ${({ colour }) => {
    if (colour) {
      return css`
        fill: ${colour};
      `;
    }
  }}
`;

const StyledHeading = styled.h3`
  ${fontSize(25)};
  margin-bottom: 10px;
`;

const StyledSubText = styled.p`
  line-height: 2;
`;

const DiversityTravelApp = ({
  heading,
  text,
  appStoreUrl,
  googlePlayUrl,
  items,
  image,
}) => (
  <StyledDiversityTravelApp>
    <Container>
      <StyledHeader>
        <Heading>{heading}</Heading>
        {text && <StyledText>{text}</StyledText>}
        <StyledLinks>
          <a href={appStoreUrl}>
            <img src={appleAppStore} alt={'Downolad on the app store'} />
          </a>
          <a href={googlePlayUrl}>
            <img src={googlePlayStore} alt={'Get it on Google Play'} />
          </a>
        </StyledLinks>
      </StyledHeader>
      <StyledItems>
        <StyledImageWrapper>
          <StyledBlobShape image={blobShape} colour={brandColours.tertiary} />
          <StyledImage image={image.gatsbyImageData} alt={image.alt} />
        </StyledImageWrapper>
        {items.map(({ heading, text }, id) => (
          <div key={id}>
            <StyledHeading>{heading}</StyledHeading>
            {text && <StyledSubText>{text}</StyledSubText>}
          </div>
        ))}
      </StyledItems>
    </Container>
  </StyledDiversityTravelApp>
);

export default DiversityTravelApp;
