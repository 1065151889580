import fetch from 'isomorphic-fetch';

export const submitRecapture = e => {
  e.preventDefault();
  window.grecaptcha.ready(function () {
    window.grecaptcha
      .execute(process.env.GATSBY_GOOGLE_RECAPTURE_SITE_KEY, {
        action: 'submit',
      })
      .then(async function (token) {
        // Add your logic to submit to your backend server here.
        // Check token
        const recaptureResponse = await fetch('/.netlify/functions/recapture', {
          method: 'POST',
          body: JSON.stringify({
            token: token,
          }),
        }).then(response => response.json());

        if (recaptureResponse.score >= 0.7) {
          e.target.action =
            'https://analytics-eu.clickdimensions.com/forms/?visitor=lead';
          e.target.submit();
        }
      });
  });
};
