import React from 'react';
import SlickSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';
import {
  standardColours,
  brandColours,
  fontSize,
  standardTransition,
  minBreakpointQuery,
} from '../../styles';

const StyledSlider = styled(SlickSlider)`
  position: relative;

  .slick-arrow {
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    color: ${brandColours.primary};
    ${fontSize(0)};
    background: ${brandColours.septenary};
    border: none;
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 ${standardColours.transparentBlack(0.3)};
    cursor: pointer;
    transition: ${standardTransition('color')},
      ${standardTransition('background-color')};

    ${minBreakpointQuery.tiny`
      height: 60px;
      width: 60px;
    `}

    ${minBreakpointQuery.small`
      height: 80px;
      width: 80px;
    `}

    ${minBreakpointQuery.medium`
      height: 100px;
      width: 100px;
    `}

    &:before {
      ${fontSize(26)};
      line-height: 0.5;

      ${minBreakpointQuery.tiny`
        ${fontSize(30)};
      `}

      ${minBreakpointQuery.small`
        ${fontSize(34)};
      `}

      ${minBreakpointQuery.medium`
        ${fontSize(38)};
      `}
    }

    &:hover {
      color: ${standardColours.white};
      background-color: ${brandColours.primary};
    }

    &.slick-prev {
      &:before {
        content: '←';
      }
    }

    &.slick-next {
      &:before {
        content: '→';
      }
    }
  }
`;

export const Slider = ({ sliderOptions, children, ...props }) => (
  <StyledSlider {...sliderOptions} {...props}>
    {children}
  </StyledSlider>
);
