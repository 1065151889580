import React from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import { minBreakpointQuery, brandColours, fontWeights } from '../styles';
import { slugify } from '../utils';
import usFlag from '../images/us-flag.svg';
import ukFlag from '../images/uk-flag.svg';
import nlFlag from '../images/nl-flag.svg';
import frFlag from '../images/fr-flag.svg';
import deFlag from '../images/de-flag.svg';

const StyledLocaleSwitcher = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: ${({ isGeolocationQuestion }) => (isGeolocationQuestion ? '22' : '8')}px;
`;

const StyledLink = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  font-weight: ${fontWeights.bold};
  background: none;
  border: none;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledFlag = styled.span`
  position: relative;
  display: block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  overflow: hidden;

  ${minBreakpointQuery.tsmall`
    height: 22px;
    width: 22px;
  `}

  ${minBreakpointQuery.smedium`
    height: 24px;
    width: 24px;
  `}

  ${({ isGeolocationQuestion }) => {
    if (isGeolocationQuestion) {
      return css`
        margin-left: 10px;

        ${minBreakpointQuery.mlarge`
          height: 26px;
          width: 26px;
        `}
      `;
    } else {
      return css`
        ${minBreakpointQuery.mlarge`
          height: 18px;
          width: 18px;
        `}
      `;
    }
  }}

  ${({ active }) => {
    if (active) {
      return css`
        border: 1px solid ${brandColours.primary};
      `;
    }
  }}
`;

const StyledImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const LocaleSwitcher = ({ locale, isGeolocationQuestion, ...props }) => {
  const {
    datoCmsSite: { name },
  } = useStaticQuery(graphql`
    query LocaleSwitcherQuery {
      datoCmsSite {
        name
      }
    }
  `);

  const locales = [
    {
      name: 'US Site',
      code: 'en-US',
      slug: '/',
      flag: usFlag,
    },
    {
      name: 'UK Site',
      code: 'en-GB',
      slug: '/uk/',
      flag: ukFlag,
    },
    {
      name: 'NL Site',
      code: 'nl-NL',
      slug: '/nl/',
      flag: nlFlag,
    },
    {
      name: 'FR Site',
      code: 'fr-FR',
      slug: '/fr/',
      flag: frFlag,
    },
    {
      name: 'DE Site',
      code: 'de-DE',
      slug: '/de/',
      flag: deFlag,
    },
  ];

  const localStorageKey = `${slugify(name)}:locale`;

  const switchLocale = (code, slug) => {
    localStorage.setItem(localStorageKey, code);

    if (isGeolocationQuestion && code === locale) {
      window.location.href = slug;
    } else {
      navigate(slug);
    }
  };

  return (
    <StyledLocaleSwitcher
      isGeolocationQuestion={isGeolocationQuestion}
      {...props}
    >
      {locales.map(({ name, code, slug, flag }, id) => (
        <StyledLink key={id} onClick={() => switchLocale(code, slug)}>
          {isGeolocationQuestion &&
            `${code === locale ? 'Stay on' : 'Switch to'} ${name}`}
          <StyledFlag
            isGeolocationQuestion={isGeolocationQuestion}
            active={!isGeolocationQuestion && code === locale}
          >
            <StyledImage src={flag} alt={name} loading="lazy" />
          </StyledFlag>
        </StyledLink>
      ))}
    </StyledLocaleSwitcher>
  );
};

export default LocaleSwitcher;
