import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  fontWeights,
  fontSize,
  visuallyHidden,
} from '../styles';
import { Container } from './ui';

const StyledIconContentBlocks = styled.section`
  ${sectionMargins()};
`;

const StyledHeading = styled.h2`
  ${visuallyHidden()};
`;

const StyledItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 30px;

  ${minBreakpointQuery.small`
    gap: 40px;
  `}

  ${minBreakpointQuery.large`
    gap: 50px;
  `}
`;

const StyledItem = styled.article`
  max-width: 180px;
  text-align: center;
`;

const StyledIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  height: 80px;
  width: 100px;
`;

const StyledIcon = styled.img`
  max-height: 80px;
  max-width: 100px;
`;

const StyledText = styled.p`
  margin-top: 20px;
  font-weight: ${fontWeights.bold};
  ${fontSize(18)};
  line-height: 1.5;

  ${minBreakpointQuery.small`
    margin-top: 25px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 30px;
  `}
`;

const IconContentBlocks = ({ heading, items }) => (
  <StyledIconContentBlocks>
    <Container>
      <StyledHeading>{heading}</StyledHeading>
      <StyledItems>
        {items.map(({ id, icon, text }) => (
          <StyledItem key={id}>
            <StyledIconWrapper>
              <StyledIcon src={icon.url} alt={icon.alt} loading="lazy" />
            </StyledIconWrapper>
            <StyledText>{text}</StyledText>
          </StyledItem>
        ))}
      </StyledItems>
    </Container>
  </StyledIconContentBlocks>
);

export default IconContentBlocks;
