import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  maxBreakpointQuery,
  brandColours,
  brandFonts,
  fontWeights,
  fontSize,
  fluidFontSize,
  zIndexLayers,
  standardTransition,
} from '../styles';
import { Link } from './ui';
import { getCollectionLocaleFields } from '../utils';

const StyledNavigation = styled.div``;

const StyledToggle = styled.button`
  ${maxBreakpointQuery.mlarge`
    position: relative;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 80px;
    background-color: ${brandColours.nonary};
    border: none;
    cursor: pointer;
  `}

  ${maxBreakpointQuery.small`
    width: 72px;
  `}

  ${maxBreakpointQuery.tsmall`
    width: 64px;
  `}

  ${maxBreakpointQuery.tiny`
    width: 56px;
  `}

  ${minBreakpointQuery.mlarge`
    display: none;
  `}

  span {
    ${maxBreakpointQuery.mlarge`
      display: block;
      height: 3px;
      width: 25px;
      margin: 7px auto;
      background-color: ${brandColours.secondary};
      border-radius: 2px;
    `}
  }
`;

const StyledNav = styled.nav`
  ${maxBreakpointQuery.mlarge`
    position: absolute;
    top: auto;
    right: 0;
    left: 0;
    z-index: ${zIndexLayers.fourth};
    display: ${({ displayNav }) => (displayNav ? 'block' : 'none')};
    margin-top: 1px;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: ${brandColours.septenary}
  `}

  ${maxBreakpointQuery.tsmall`
    padding-top: 20px;
    padding-bottom: 20px;
  `}

  ${minBreakpointQuery.mlarge`
    display: flex;
    justify-content: space-between;
  `}
`;

const StyledList = styled.ul``;

// moved up for hover
const StyledSubList = styled.ul`
  ${minBreakpointQuery.mlarge`
    position: absolute;
    left: -9999em;
    z-index: 1;
    padding: 20px 30px;
    width: 280px;
    background-color: ${brandColours.quaternary};
    border-radius: 15px;
    transform: translateX(-30px);
  `}

  ${minBreakpointQuery.xlarge`
    padding: 25px 40px;
    transform: translateX(-40px);
  `}
`;

const StyledItem = styled.li`
  ${maxBreakpointQuery.mlarge`
    margin-top: 5px;
  `}

  ${minBreakpointQuery.mlarge`
    position: relative;
    display: inline-block;
    margin-left: 8px;
  `}

  ${minBreakpointQuery.large`
    margin-left: 14px;
  `}

  ${minBreakpointQuery.xlarge`
    margin-left: 18px;
  `}

  &:after {
    ${minBreakpointQuery.mlarge`
      content: '';
      position: absolute;
      z-index: 1;
      right: 0;
      bottom: -2px;
      left: 0;
      height: 2px;
      background-color: ${brandColours.primary};
      opacity: 0;
    `}
  }

  &:first-child {
    ${maxBreakpointQuery.mlarge`
      margin-top: 0;
    `}

    ${minBreakpointQuery.mlarge`
      margin-left: 0;
    `}
  }

  &:hover {
    ${minBreakpointQuery.mlarge`
      border-bottom: 2px solid ${brandColours.primary};
    `}

    ${StyledSubList} {
      ${minBreakpointQuery.mlarge`
        left: 0;
      `}
    }
  }

  &:hover {
    &:after {
      ${minBreakpointQuery.mlarge`
        opacity: 1;
      `}
    }
  }
`;

const commonLinkStyles = () => {
  return css`
    transition: ${standardTransition('color')};

    ${maxBreakpointQuery.mlarge`
      display: block;
      padding: 5px 10px;
    `}
  `;
};

const StyledLink = styled(Link)`
  ${commonLinkStyles()};
  font-weight: ${fontWeights.bold};

  ${maxBreakpointQuery.mlarge`
    font-family: ${brandFonts.secondary};
    ${fluidFontSize(
      '22px',
      '32px',
      breakpointSizes.tiny,
      breakpointSizes.mlarge
    )};
  `}

  ${minBreakpointQuery.mlarge`
    padding-top: 8px;
    padding-bottom: 8px;
    ${fontSize(15)};
    text-transform: uppercase;
  `}

  ${minBreakpointQuery.large`
    padding-top: 12px;
    padding-bottom: 12px;
    ${fontSize(16)};
  `}
`;

const StyledSubItem = styled.li`
  ${minBreakpointQuery.mlarge`
    margin-top: 14px;
  `}

  &:first-child {
    ${minBreakpointQuery.mlarge`
      margin-top: 0;
    `}
  }
`;

const StyledSubLink = styled(Link)`
  ${commonLinkStyles()};

  ${maxBreakpointQuery.tsmall`
    ${fontSize(14)};
  `}
`;

const NavigationItem = ({ text, page, treeChildren }) => (
  <StyledItem>
    <StyledLink to={page}>{text}</StyledLink>
    {treeChildren && treeChildren.length > 0 && (
      <StyledSubList>
        {treeChildren
          .sort((a, b) => a.position - b.position)
          .map(({ id, page, text }) =>
            page ? (
              <StyledSubItem key={id}>
                <StyledSubLink to={page}>{text}</StyledSubLink>
              </StyledSubItem>
            ) : (
              ''
            )
          )}
      </StyledSubList>
    )}
  </StyledItem>
);

const Navigation = ({ locale, displayNav, setDisplayNav, ...props }) => {
  const { allDatoCmsNavigation } = useStaticQuery(graphql`
    query NavigationQuery {
      allDatoCmsNavigation(
        filter: { root: { eq: true } }
        sort: { fields: position }
      ) {
        group(field: locale) {
          fieldValue
          nodes {
            id
            text
            page {
              ...LinkFragment
            }
            treeChildren {
              id
              text
              page {
                ...LinkFragment
              }
              position
            }
          }
        }
      }
    }
  `);

  const { nodes } = getCollectionLocaleFields(allDatoCmsNavigation, locale);

  return (
    <StyledNavigation {...props}>
      <StyledToggle
        aria-label="Open / Close Navigation"
        onClick={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          setDisplayNav(!displayNav);
        }}
      >
        <span />
        <span />
        <span />
      </StyledToggle>
      <StyledNav displayNav={displayNav}>
        <StyledList>
          {nodes.map(({ id, text, page, treeChildren }) =>
            page ? (
              <NavigationItem
                key={id}
                text={text}
                page={page}
                treeChildren={treeChildren}
              />
            ) : (
              ''
            )
          )}
        </StyledList>
      </StyledNav>
    </StyledNavigation>
  );
};

export default Navigation;
