import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  brandColours,
  brandFonts,
  fontSize,
  visuallyHidden,
} from '../styles';
import { Container } from './ui';
import { Button } from './ui';

const StyledImageCtaCards = styled.section`
  ${sectionMargins('30px', '70px')};
`;

const StyledHiddenHeading = styled.h2`
  ${visuallyHidden()};
`;

const StyledItems = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: -10px;
`;

const StyledItem = styled.article`
  margin: 10px;
  border-radius: 15px;
  overflow: hidden;

  ${minBreakpointQuery.small`
    width: calc(50% - 20px);
  `}

  ${minBreakpointQuery.mlarge`
    width: calc((100% - 60px) / 3);
  `}

  ${({ backgroundColour }) => {
    if (backgroundColour === 'Dark Orange') {
      return css`
        color: ${brandColours.septenary};
        background-color: ${brandColours.secondary};
      `;
    } else if (backgroundColour === 'Light Orange') {
      return css`
        background-color: ${brandColours.tertiary};
      `;
    } else {
      return css`
        color: ${brandColours.quaternary};
        background-color: ${brandColours.primary};
      `;
    }
  }}
`;

const StyledImage = styled(GatsbyImage)``;

const StyledContent = styled.div`
  padding: 25px 20px 30px;
  text-align: center;
`;

const StyledOverline = styled.p`
  margin-bottom: 5px;
  ${fontSize(14)};

  ${minBreakpointQuery.smedium`
    margin-bottom: 6px;
    ${fontSize(16)};
  `}
`;

const StyledHeading = styled.h3`
  font-family: ${brandFonts.secondary};
  ${fontSize(24)};

  ${minBreakpointQuery.smedium`
    ${fontSize(26)};
  `}
`;

const StyledText = styled.p`
  margin-top: 14px;
  line-height: 1.6;

  ${minBreakpointQuery.smedium`
    margin-top: 16px;
    ${fontSize(18)};
  `}
`;

const StyledButton = styled(Button)`
  margin-top: 20px;

  ${minBreakpointQuery.smedium`
    margin-top: 22px;
  `}

  ${({ backgroundColour }) => {
    if (backgroundColour === 'Dark Green') {
      return css`
        &:hover {
          background-color: ${brandColours.octonary};
        }
      `;
    }
  }}
`;

const ImageCtaCards = ({ heading, items }) => (
  <StyledImageCtaCards>
    <Container>
      <StyledHiddenHeading>{heading}</StyledHiddenHeading>
      <StyledItems>
        {items.map(
          ({ id, image, overline, heading, text, link, backgroundColour }) => (
            <StyledItem key={id} backgroundColour={backgroundColour}>
              <StyledImage image={image.gatsbyImageData} alt={image.alt} />
              <StyledContent>
                <StyledOverline>{overline}</StyledOverline>
                <StyledHeading>{heading}</StyledHeading>
                {text && <StyledText>{text}</StyledText>}
                <StyledButton
                  {...(link.page
                    ? {
                        to: link.page,
                      }
                    : {
                        href: link.url,
                        target: '_blank',
                        rel: 'noopener noreferrer',
                      })}
                  alt={backgroundColour !== 'Light Orange'}
                  backgroundColour={backgroundColour}
                >
                  {link.text}
                </StyledButton>
              </StyledContent>
            </StyledItem>
          )
        )}
      </StyledItems>
    </Container>
  </StyledImageCtaCards>
);

export default ImageCtaCards;

export const ImageCtaCardsItemFragment = graphql`
  fragment ImageCtaCardsItemFragment on DatoCmsImageCtaCard {
    id
    image {
      gatsbyImageData(width: 560, height: 220)
      alt
    }
    overline
    heading
    text
    link {
      ... on DatoCmsLink {
        text
        page {
          ...LinkFragment
        }
      }
      ... on DatoCmsExternalLink {
        text
        url
      }
    }
    backgroundColour
  }
`;
