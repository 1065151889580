import React from 'react';
import styled from 'styled-components';
import { headingStyles } from '../../styles';

const StyledHeading = styled.h2`
  ${({ alt }) => headingStyles(alt)};
`;

export const Heading = ({ alt, children, ...props }) => (
  <StyledHeading alt={alt} {...props}>
    {children}
  </StyledHeading>
);
