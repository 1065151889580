import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery, sectionMargins } from '../styles';
import { Container, Heading, HtmlContent } from './ui';

const StyledLogoContentBlocks = styled.section`
  ${sectionMargins()};
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 30px;
  text-align: center;

  ${minBreakpointQuery.small`
    margin-bottom: 40px;
  `}

  ${minBreakpointQuery.medium`
    margin-bottom: 50px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 60px;
  `}
`;

const StyledItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 30px;

  ${minBreakpointQuery.small`
    gap: 40px;
  `}

  ${minBreakpointQuery.large`
    gap: 50px;
  `}
`;

const StyledItem = styled.article`
  max-width: 340px;
  text-align: center;
`;

const StyledLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  height: 50px;
  width: 170px;

  ${minBreakpointQuery.small`
    height: 60px;
    width: 200px;
  `}

  ${minBreakpointQuery.large`
    height: 70px;
    width: 230px;
  `}
`;

const StyledLogo = styled.img`
  max-height: 50px;
  max-width: 170px;

  ${minBreakpointQuery.small`
    max-height: 60px;
    max-width: 200px;
  `}

  ${minBreakpointQuery.large`
    max-height: 70px;
    max-width: 230px;
  `}
`;

const StyledText = styled(HtmlContent)`
  margin-top: 20px;

  ${minBreakpointQuery.small`
    margin-top: 25px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 30px;
  `}
`;

const LogoContentBlocks = ({ heading, items }) => (
  <StyledLogoContentBlocks>
    <Container>
      <StyledHeading alt={true}>{heading}</StyledHeading>
      <StyledItems>
        {items.map(({ id, logo, text }) => (
          <StyledItem key={id}>
            <StyledLogoWrapper>
              <StyledLogo src={logo.url} alt={logo.alt} loading="lazy" />
            </StyledLogoWrapper>
            <StyledText html={text} />
          </StyledItem>
        ))}
      </StyledItems>
    </Container>
  </StyledLogoContentBlocks>
);

export default LogoContentBlocks;
