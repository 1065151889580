import React from 'react';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  headingStyles,
  standardTransition,
} from '../styles';
import {
  BlobBackground,
  Container,
  Heading,
  HtmlContent,
  Button,
  Link,
} from './ui';
import ImageLinkCards from './ImageLinkCards';

const StyledContentFeaturedLinksImageLinkCards = styled.section`
  ${sectionMargins()};
  color: ${({ isHomepage }) =>
    isHomepage ? brandColours.septenary : standardColours.white};

  ${({ isHomepage }) => {
    if (isHomepage) {
      return css`
        ${minBreakpointQuery.smedium`
          display: flex;
          flex-direction: column;
        `}
      `;
    }
  }}
`;

const StyledBlobBackground = styled(BlobBackground)`
  ${minBreakpointQuery.smedium`
    order: 2;
  `}
`;

const StyledInner = styled.div`
  display: grid;
  gap: 30px;

  ${minBreakpointQuery.small`
    gap: 40px;
  `}

  ${minBreakpointQuery.smedium`
    grid-template-columns: 1.2fr 1fr;
    align-items: center;
  `}

  ${minBreakpointQuery.medium`
    gap: 60px;
  `}

  ${minBreakpointQuery.mlarge`
    gap: 80px;
  `}

  ${minBreakpointQuery.large`
    gap: 100px;
  `}

  ${({ hasImageLinkCards, isHomepage }) => {
    if (hasImageLinkCards && isHomepage) {
      return css`
        ${maxBreakpointQuery.smedium`
          padding-bottom: 105px;
        `}

        ${maxBreakpointQuery.tsmall`
          padding-bottom: 90px;
        `}

        ${minBreakpointQuery.smedium`
          padding-top: 120px;
        `}

        ${minBreakpointQuery.mlarge`
          padding-top: 135px;
        `}

        ${minBreakpointQuery.large`
          padding-top: 150px;
        `}

        ${minBreakpointQuery.xlarge`
          padding-top: 165px;
        `}

        ${minBreakpointQuery.xxlarge`
          padding-top: 180px;
        `}
      `;
    } else if (hasImageLinkCards) {
      return css`
        padding-bottom: 90px;

        ${minBreakpointQuery.tsmall`
          padding-bottom: 105px;
        `}

        ${minBreakpointQuery.smedium`
          padding-bottom: 120px;
        `}

        ${minBreakpointQuery.mlarge`
          padding-bottom: 135px;
        `}

        ${minBreakpointQuery.large`
          padding-bottom: 150px;
        `}

        ${minBreakpointQuery.xlarge`
          padding-bottom: 165px;
        `}

        ${minBreakpointQuery.xxlarge`
          padding-bottom: 180px;
        `}
      `;
    }
  }}
`;

const StyledContent = styled.div``;

const StyledText = styled(HtmlContent)`
  margin-top: 16px;

  ${minBreakpointQuery.small`
    margin-top: 20px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 24px;
  `}
`;

const StyledButton = styled(Button)`
  margin-top: 16px;

  ${minBreakpointQuery.small`
    margin-top: 20px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 24px;
  `}

  ${({ isHomepage }) => {
    if (isHomepage) {
      return css`
        &:hover {
          color: ${brandColours.primary};
          background-color: ${brandColours.tertiary};
        }
      `;
    }
  }}
`;

const StyledFeaturedLinks = styled.div``;

const StyledSubheading = styled.h3`
  ${headingStyles()};
`;

const StyledFeaturedLink = styled(Link)`
  position: relative;
  display: block;
  margin-top: 20px;
  padding: 20px 80px 20px 20px;
  font-weight: ${fontWeights.bold};
  ${fontSize(20)};
  line-height: 1.4;
  border: 1px solid
    ${({ isHomepage }) =>
      isHomepage ? brandColours.septenary : standardColours.white};
  border-radius: 10px;
  transition: ${standardTransition('color')},
    ${standardTransition('background-color')};

  ${minBreakpointQuery.small`
    margin-top: 25px;
    padding-right: 90px;
    ${fontSize(22)};
  `}

  ${minBreakpointQuery.large`
    margin-top: 30px;
    padding: 25px 100px 25px 25px;
    ${fontSize(25)};
  `}

  &:after {
    content: '→';
    position: absolute;
    top: 50%;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 3px;
    height: 40px;
    width: 40px;
    color: ${brandColours.primary};
    line-height: 0.5;
    background-color: ${({ isHomepage }) =>
      isHomepage ? brandColours.quaternary : brandColours.septenary};
    border: 1px dashed ${brandColours.primary};
    border-radius: 50%;
    transform: translateY(-50%);

    ${minBreakpointQuery.small`
      padding-bottom: 4px;
      height: 45px;
      width: 45px;
    `}

    ${minBreakpointQuery.large`
      padding-bottom: 5px;
      height: 50px;
      width: 50px;
    `}
  }

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    color: ${({ isHomepage }) =>
      isHomepage ? brandColours.primary : brandColours.secondary};
    background-color: ${({ isHomepage }) =>
      isHomepage ? brandColours.quaternary : brandColours.septenary};
  }
`;

const StyledImageLinkCards = styled(ImageLinkCards)`
  position: relative;
  z-index: 2;

  ${({ isHomepage }) => {
    if (isHomepage) {
      return css`
        ${maxBreakpointQuery.smedium`
          margin-top: -150px;
        `}

        ${maxBreakpointQuery.tsmall`
          margin-top: -100px;
        `}

        ${minBreakpointQuery.smedium`
          order: 1;
          margin-bottom: -200px;
        `}

        ${minBreakpointQuery.mlarge`
          margin-bottom: -250px;
        `}

        ${minBreakpointQuery.xlarge`
          margin-bottom: -300px;
        `}
      `;
    } else {
      return css`
        margin-top: -100px;

        ${minBreakpointQuery.tsmall`
          margin-top: -150px;
        `}

        ${minBreakpointQuery.smedium`
          margin-top: -200px;
        `}

        ${minBreakpointQuery.mlarge`
          margin-top: -250px;
        `}

        ${minBreakpointQuery.xlarge`
          margin-top: -300px;
        `}
      `;
    }
  }}
`;

const ContentFeaturedLinksImageLinkCards = ({
  heading,
  text,
  link,
  featuredLinksHeading,
  featuredLinks,
  imageLinkCards,
  isHomepage,
}) => (
  <StyledContentFeaturedLinksImageLinkCards isHomepage={isHomepage}>
    <StyledBlobBackground
      colour={isHomepage ? brandColours.primary : brandColours.secondary}
      isHomepage={isHomepage}
    >
      <Container>
        <StyledInner
          hasImageLinkCards={imageLinkCards.length > 0}
          isHomepage={isHomepage}
        >
          <StyledContent>
            <Heading alt={true}>{heading}</Heading>
            <StyledText html={text} />
            {link && (
              <StyledButton
                to={link.page}
                alt={isHomepage}
                isHomepage={isHomepage}
              >
                {link.text}
              </StyledButton>
            )}
          </StyledContent>
          <StyledFeaturedLinks>
            {featuredLinksHeading && (
              <StyledSubheading>{featuredLinksHeading}</StyledSubheading>
            )}
            {featuredLinks.map(({ id, text, page }) => (
              <StyledFeaturedLink key={id} to={page} isHomepage={isHomepage}>
                {text}
              </StyledFeaturedLink>
            ))}
          </StyledFeaturedLinks>
        </StyledInner>
      </Container>
    </StyledBlobBackground>
    {imageLinkCards.length > 0 && (
      <StyledImageLinkCards items={imageLinkCards} isHomepage={isHomepage} />
    )}
  </StyledContentFeaturedLinksImageLinkCards>
);

export default ContentFeaturedLinksImageLinkCards;
