import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  brandColours,
  fontSize,
  fontWeights,
  minBreakpointQuery,
  sectionMargins,
  standardColours,
  standardTransition,
} from '../styles';
import { BlobBackground, Container, Heading, Video } from './ui';

const StyledVideoPlayer = styled.section`
  ${sectionMargins()};
`;

const StyledHeader = styled.header`
  text-align: center;
  margin-bottom: 30px;
`;

const StyledText = styled.header`
  margin-top: 20px;
`;

const StyledItems = styled.div``;

const StyledVideoWrapper = styled.div`
  display: grid;
  gap: 30px;

  ${minBreakpointQuery.small`
    gap: 35px;
  `}

  ${minBreakpointQuery.smedium`
    grid-template-columns: 0.3fr 0.7fr;
    align-items: center;
  `}

  ${minBreakpointQuery.medium`
    gap: 40px;
  `}

  ${minBreakpointQuery.large`
    gap: 45px;
  `}

  ${minBreakpointQuery.xlarge`
    gap: 50px;
  `}
`;

const StyledTimestamps = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 10px;
  counter-reset: number-counter;

  ${minBreakpointQuery.smedium`
    grid-row: 1;
  `}
`;

const StyledTimestamp = styled.li`
  display: flex;
  align-items: center;
  gap: 10px;
  counter-increment: number-counter;

  &::before {
    content: counter(number-counter);
    ${fontSize(18)};
    padding: 8px 15px;
    border-radius: 50%;
    color: ${brandColours.quaternary};
    background-color: ${brandColours.secondary};
  }

  &:nth-child(even) {
    flex-direction: row-reverse;
  }
`;

const StyledTimestampText = styled.button`
  border-radius: 40px;
  padding: 15px;
  border: none;
  ${fontSize(12)};
  flex-grow: 1;
  font-weight: ${fontWeights.bold};
  transition: ${standardTransition('color')},
    ${standardTransition('background-color')};

  ${minBreakpointQuery.small`
    padding: 20px;
    ${fontSize(14)};
  `}

  ${minBreakpointQuery.large`
    padding: 25px;
    ${fontSize(16)};
  `}

  ${({ active }) => {
    if (active) {
      return css`
        background-color: ${brandColours.secondary};
        color: ${standardColours.white};
      `;
    } else {
      return css`
        background-color: ${brandColours.quaternary};
        color: ${brandColours.primary};
      `;
    }
  }}
`;

const StyledSelection = styled.div`
  display: grid;
  gap: 10px;
  margin-top: 30px;

  ${minBreakpointQuery.tsmall`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${minBreakpointQuery.small`
    margin-top: 40px;
  `}

  ${minBreakpointQuery.medium`
    margin-top: 50px;
  `}

  ${minBreakpointQuery.mlarge`
    margin-top: 60px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 70px;
  `}

  ${minBreakpointQuery.xlarge`
    margin-top: 80px;
  `}
`;

const StyledVideoButtom = styled.button`
  background: none;
  border: none;
  display: flex;
  gap: 25px;
  align-items: center;
  padding: 0;
  padding-top: 10px;
  transition: ${standardTransition('border-top')},
    ${standardTransition('margin-top')};
  color: ${brandColours.septenary};
  ${fontSize(18)};
  font-weight: ${fontWeights.bold};

  ${minBreakpointQuery.small`
    padding-top: 15px;
    ${fontSize(20)};
  `}

  ${minBreakpointQuery.medium`
    padding-top: 20px;
    ${fontSize(22)};
  `} 

  ${minBreakpointQuery.large`
    padding-top: 25px;
    ${fontSize(25)};
  `} 
  
  ${minBreakpointQuery.xlarge`
    padding-top: 30px;
  `} 
  
  ${({ active }) => {
    if (active) {
      return css`
        border-top: solid 11px ${brandColours.septenary};
      `;
    } else {
      return css`
        border-top: solid 1px ${brandColours.septenary};
        ${minBreakpointQuery.tsmall`
          margin-top: 5px;
        `}
      `;
    }
  }};
`;

const StyledIconWrapper = styled.span`
  width: 30px;
  height: 30px;
  background-color: ${brandColours.septenary};
  border-radius: 50%;

  ${minBreakpointQuery.small`
    width: 35px;
    height: 35px;
  `}

  ${minBreakpointQuery.medium`
    width: 40px;
    height: 40px;
  `}

  ${minBreakpointQuery.large`
    width: 45px;
    height: 45px;
  `}

  ${minBreakpointQuery.xlarge`
    width: 50px;
    height: 50px;
  `}
`;

const StyledIcon = styled.img`
  width: 100%;
  height: 100%;
  padding: 8px;

  ${minBreakpointQuery.medium`
    padding: 10px;
  `}

  ${minBreakpointQuery.large`
    padding: 12px;
  `}

  ${minBreakpointQuery.xlarge`
    padding: 15px;
  `}
`;

const VideoPlayer = ({ heading, text, items }) => {
  const [activeVideo, setActiveVideo] = useState(items[0]);
  const [activeTimestamp, setActiveTimestamp] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const handleTimeUpdate = time => {
    setCurrentTime(time);
  };

  const getActiveTimestampIndex = (time, timestamps) => {
    for (let i = 0; i < timestamps.length; i++) {
      if (
        timestamps[i + 1] &&
        time > timestamps[i].seconds &&
        time < timestamps[i + 1].seconds
      ) {
        return i;
      } else if (!timestamps[i + 1] && time > timestamps[i].seconds) {
        return i;
      }
    }
    return -1;
  };

  const handleVideoChange = selectedVideo => {
    setActiveVideo(selectedVideo);
    setActiveTimestamp(0);
  };

  return (
    <StyledVideoPlayer>
      <StyledHeader>
        <Container narrow={true}>
          <Heading>{heading}</Heading>
          {text && <StyledText>{text}</StyledText>}
        </Container>
      </StyledHeader>
      <BlobBackground colour={brandColours.primary}>
        <Container>
          <StyledItems>
            <StyledVideoWrapper>
              <Video
                data={activeVideo.video}
                onTimeUpdate={handleTimeUpdate}
                activeTimestamp={activeTimestamp}
                autoPlay={true}
                isVideoPlayer={true}
              />
              <StyledTimestamps>
                {activeVideo.timestamps.map(({ seconds, description }, id) => (
                  <StyledTimestamp
                    key={id}
                    onClick={() => setActiveTimestamp(seconds)}
                  >
                    <StyledTimestampText
                      active={
                        getActiveTimestampIndex(
                          currentTime,
                          activeVideo.timestamps
                        ) === id
                      }
                    >
                      {description}
                    </StyledTimestampText>
                  </StyledTimestamp>
                ))}
              </StyledTimestamps>
            </StyledVideoWrapper>
          </StyledItems>
          <StyledSelection>
            {items.map((item, id) => (
              <StyledVideoButtom
                key={id}
                active={item === activeVideo}
                onClick={() => handleVideoChange(item)}
              >
                <StyledIconWrapper>
                  <StyledIcon
                    src={item.icon.url}
                    alt={item.icon.alt}
                    loading={'lazy'}
                  />
                </StyledIconWrapper>
                {item.heading}
              </StyledVideoButtom>
            ))}
          </StyledSelection>
        </Container>
      </BlobBackground>
    </StyledVideoPlayer>
  );
};

export default VideoPlayer;
