import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { minBreakpointQuery, brandColours, fontSize } from '../styles';
import { Container } from './ui';
import { getSingleLocaleFields } from '../utils';

const StyledSitewideNotice = styled.div`
  padding: 8px 0;
  text-align: center;
  background-color: ${brandColours.primary};
`;

const StyledText = styled.div`
  p {
    color: ${brandColours.quaternary};
    ${fontSize(12)};
    text-transform: uppercase;

    ${minBreakpointQuery.small`
      ${fontSize(14)};
    `}

    a {
      text-decoration: underline;
    }
  }
`;

const SitewideNotice = ({ locale }) => {
  const { allDatoCmsHeader } = useStaticQuery(graphql`
    query SitewideNoticeQuery {
      allDatoCmsHeader {
        nodes {
          locale
          sitewideNotice
        }
      }
    }
  `);

  const { sitewideNotice } = getSingleLocaleFields(allDatoCmsHeader, locale);

  return (
    sitewideNotice && (
      <StyledSitewideNotice>
        <Container>
          <StyledText dangerouslySetInnerHTML={{ __html: sitewideNotice }} />
        </Container>
      </StyledSitewideNotice>
    )
  );
};

export default SitewideNotice;
