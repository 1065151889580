import { css } from 'styled-components';
import { fontSize } from '.';
import {
  minBreakpointQuery,
  fontWeights,
  standardTransition,
} from '../../styles';
import { brandColours, brandFonts } from '../variables';

export const formOuterStyles = () => {
  return css`
    padding: 20px;
    color: ${brandColours.quaternary};
    background-color: ${brandColours.primary};
    border-radius: 10px;

    ${minBreakpointQuery.tiny`
      padding: 30px;
    `}

    ${minBreakpointQuery.small`
      padding: 40px;
    `}

    ${minBreakpointQuery.medium`
      padding: 50px;
    `}

    ${minBreakpointQuery.large`
      padding: 60px;
    `}

    ${minBreakpointQuery.xxlarge`
      padding: 70px;
    `}

    ${minBreakpointQuery.xxxxlarge`
      padding: 80px;
    `}
  `;
};

export const formInnerStyles = () => {
  return css`
    margin: 0 auto;
    max-width: 780px;
  `;
};

export const formStyles = () => {
  return css`
    padding-top: 20px;
    display: grid;
    gap: 15px;

    ${minBreakpointQuery.small`
      padding-top: 30px;
      gap: 20px;
    `}

    ${minBreakpointQuery.medium`
      grid-template-columns: repeat(2, 1fr);
    `}

    ${minBreakpointQuery.large`
      padding-top: 40px;
      gap: 25px;
    `}
  `;
};

export const inputStyles = () => {
  return css`
    margin-top: 10px;
    padding: 10px 15px;
    height: 50px;
    width: 100%;
    background-color: ${brandColours.septenary};
    border: none;
    border-radius: 30px;

    ${minBreakpointQuery.small`
      height: 55px;
    `}

    ${minBreakpointQuery.large`
      height: 60px;
    `}
  `;
};

export const textAreaStyles = () => {
  return css`
    margin-top: 10px;
    padding: 20px 15px;
    height: 100px;
    min-height: 50px;
    width: 100%;
    background-color: ${brandColours.septenary};
    border: none;
    border-radius: 30px;
    resize: vertical;

    ${minBreakpointQuery.small`
      height: 150px;
      min-height: 100px;
    `}

    ${minBreakpointQuery.large`
      height: 200px;
      min-height: 150px;
    `}
  `;
};

export const selectWrapStyles = () => {
  return css`
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 55%;
      right: 20px;
      height: 10px;
      width: 10px;
      border: 2px solid ${brandColours.primary};
      border-top: 0;
      border-left: 0;
      pointer-events: none;
      transform: translateY(-6px) rotate(45deg);
    }
  `;
};

export const selectStyles = () => {
  return css`
    width: 100%;
    appearance: none;
    height: 50px;
    background-color: ${brandColours.septenary};
    color: ${brandColours.primary};
    font-family: ${brandFonts.primary};
    ${fontSize(16)};
    line-height: 1.3;
    border: none;
    border-radius: 30px;
    padding: 10px 15px;
    margin-top: 10px;

    ${minBreakpointQuery.small`
      height: 55px;
    `}

    ${minBreakpointQuery.large`
      height: 60px;
    `}
  `;
};

export const checkboxTextStyles = () => {
  return css`
    position: relative;
    display: block;
    padding-left: 45px;
    font-weight: ${fontWeights.bold};

    ${minBreakpointQuery.large`
      ${fontSize(18)};
    `}

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      border: 2px solid ${brandColours.quaternary};
    }

    &:before {
      left: 0;
      height: 35px;
      width: 35px;
      border-radius: 50%;
      transform: translateY(-50%);
    }

    &:after {
      left: 9px;
      height: 8px;
      width: 16px;
      border-top: 0;
      border-right: 0;
      opacity: 0;
      transform: translateY(calc(-50% - 2px)) rotate(-45deg);
      transition: ${standardTransition('opacity')};
    }
  `;
};

export const formButtonStyles = () => {
  return css`
    margin-top: 10px;

    &:hover {
      color: ${brandColours.primary};
      background-color: ${brandColours.tertiary};
    }
  `;
};
