import React from 'react';
import styled, { css } from 'styled-components';
import { Svg } from './Svg';
import blobTopShape from '../../images/blob-top-shape.inline.svg';
import blobBottomShape from '../../images/blob-bottom-shape.inline.svg';

const StyledBlobBackground = styled.div`
  overflow: hidden;
`;

const StyledShape = styled(Svg)`
  position: relative;
  right: -5px;
  left: -5px;
  display: block;
  height: auto;
  width: calc(100% + 10px);

  ${({ top, bottom }) => {
    if (top) {
      return css`
        bottom: -5px;
      `;
    } else if (bottom) {
      return css`
        top: -5px;
      `;
    }
  }}

  ${({ colour }) => {
    if (colour) {
      return css`
        fill: ${colour};
      `;
    }
  }}
`;

const StyledInner = styled.div`
  position: relative;
  z-index: 1;
  padding: 20px 0;

  ${({ colour }) => {
    if (colour) {
      return css`
        background-color: ${colour};
      `;
    }
  }}
`;

export const BlobBackground = ({ colour, children, ...props }) =>
  colour ? (
    <StyledBlobBackground {...props}>
      <StyledShape image={blobTopShape} top={true} colour={colour} />
      <StyledInner colour={colour}>{children}</StyledInner>
      <StyledShape image={blobBottomShape} bottom={true} colour={colour} />
    </StyledBlobBackground>
  ) : (
    children
  );
