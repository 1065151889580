import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { minBreakpointQuery, sectionMargins } from '../styles';
import { Container, Heading, Slider } from './ui';
import imageBlobMask from '../images/image-blob-mask.svg';

const StyledImageGallery = styled.section`
  ${sectionMargins()};
  overflow: hidden;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 30px;
  text-align: center;

  ${minBreakpointQuery.small`
    margin-bottom: 40px;
  `}

  ${minBreakpointQuery.medium`
    margin-bottom: 50px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 60px;
  `}
`;

const StyledItemsContainer = styled(Container)`
  padding: 0 30px;
  max-width: 1120px;

  ${minBreakpointQuery.tiny`
    padding-right: 40px;
    padding-left: 40px;
  `}

  ${minBreakpointQuery.small`
    padding-right: 60px;
    padding-left: 60px;
  `}

  ${minBreakpointQuery.medium`
    padding-right: 80px;
    padding-left: 80px;
  `}

  ${minBreakpointQuery.large`
    padding-right: 100px;
    padding-left: 100px;
  `}
`;

const StyledItems = styled(Slider)`
  .slick-arrow {
    top: 50%;

    ${minBreakpointQuery.large`
      height: 120px;
      width: 120px;
    `}

    ${minBreakpointQuery.xxlarge`
      height: 130px;
      width: 130px;
    `}

    &.slick-prev {
      left: 0;
      transform: translate(-40%, -50%);
    }

    &.slick-next {
      right: 0;
      transform: translate(40%, -50%);
    }
  }

  .slick-list {
    overflow: visible;
  }
`;

const StyledItem = styled.div`
  padding: 0 10px;

  ${minBreakpointQuery.small`
    padding-right: 15px;
    padding-left: 15px;
  `}

  ${minBreakpointQuery.large`
    padding-right: 20px;
    padding-left: 20px;
  `}
`;

const StyledImage = styled(GatsbyImage)`
  mask-image: url('${imageBlobMask}');
  mask-repeat: no-repeat;
  mask-position: 50% 50%;
  mask-size: contain;
`;

const ImageGallery = ({ heading, items }) => (
  <StyledImageGallery>
    <Container narrow={true}>
      <StyledHeading alt={true}>{heading}</StyledHeading>
    </Container>
    <StyledItemsContainer>
      <StyledItems>
        {items.map(({ gatsbyImageData, alt }, id) => (
          <StyledItem key={id}>
            <StyledImage image={gatsbyImageData} alt={alt} />
          </StyledItem>
        ))}
      </StyledItems>
    </StyledItemsContainer>
  </StyledImageGallery>
);
export default ImageGallery;
