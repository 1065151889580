import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
  brandFonts,
  fontSize,
  fluidFontSize,
  visuallyHidden,
} from '../styles';
import { Container, Button } from './ui';
import imageBlobMask from '../images/image-blob-mask.svg';

const StyledBanner = styled.section`
  ${sectionMargins('30px', '60px')};
  overflow: hidden;
`;

const StyledInner = styled.div`
  ${({ hasImage }) => {
    if (hasImage) {
      return css`
        display: grid;
        gap: 30px;

        ${minBreakpointQuery.smedium`
          display: grid;
          grid-template-columns: ${({ isHomePage }) =>
            isHomePage ? '1.2fr 1fr' : 'repeat(2, 1fr)'};
          align-items: center;
        `}

        ${minBreakpointQuery.medium`
          gap: 50px;
        `}

        ${minBreakpointQuery.mlarge`
          gap: 70px;
        `}

        ${minBreakpointQuery.large`
          gap: 90px;
        `}
      `;
    } else {
      return css`
        margin: auto;
        max-width: 600px;
        text-align: center;
      `;
    }
  }}
`;

const StyledContent = styled.div``;

const StyledHeading = styled.h1`
  font-family: ${brandFonts.secondary};
  ${fluidFontSize(
    '35px',
    '55px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
  line-height: 1.1;

  ${({ isContactFormLandingPage }) => {
    if (isContactFormLandingPage) {
      return css`
        ${visuallyHidden()}
      `;
    }
  }}
`;

const StyledText = styled.p`
  margin-top: 12px;
  ${fontSize(15)};
  line-height: 1.7;

  ${minBreakpointQuery.medium`
    margin-top: 16px;
    ${fontSize(16)};
  `}
`;

const StyledButton = styled(Button)`
  margin-top: 16px;

  ${minBreakpointQuery.medium`
    margin-top: 20px;
  `}
`;

const StyledImage = styled(GatsbyImage)`
  margin: 0 auto;
  max-width: 580px;
  mask-image: url('${imageBlobMask}');
  mask-repeat: no-repeat;
  mask-position: 50% 50%;
  mask-size: contain;

  ${({ isHomePage }) => {
    if (isHomePage) {
      return css`
        ${maxBreakpointQuery.smedium`
          justify-self: center;
          margin-right: -60px;
          margin-left: -60px;
        `}

        ${minBreakpointQuery.smedium`
          margin-right: -100px;
        `}
      `;
    }
  }}
`;

const Banner = ({
  heading,
  text,
  link,
  image,
  contactFormLandingPage,
  isHomePage,
}) => (
  <StyledBanner>
    <Container>
      <StyledInner hasImage={image} isHomePage={isHomePage}>
        <StyledContent>
          <StyledHeading isContactFormLandingPage={contactFormLandingPage}>
            {heading}
          </StyledHeading>
          {text && <StyledText>{text}</StyledText>}
          {link && (
            <StyledButton
              {...(link.page
                ? {
                    to: link.page,
                  }
                : {
                    href: link.url,
                    target: '_blank',
                    rel: 'noopener noreferrer',
                  })}
            >
              {link.text}
            </StyledButton>
          )}
        </StyledContent>
        {image && (
          <StyledImage
            image={image.gatsbyImageData}
            alt={image.alt}
            loading="eager"
            isHomePage={isHomePage}
          />
        )}
      </StyledInner>
    </Container>
  </StyledBanner>
);

export default Banner;

export const BannerFragment = graphql`
  fragment BannerFragment on DatoCmsBannerInstance {
    text
    link {
      ... on DatoCmsLink {
        text
        page {
          ...LinkFragment
        }
      }
      ... on DatoCmsExternalLink {
        text
        url
      }
    }
    image {
      gatsbyImageData(width: 580, height: 460)
      alt
    }
  }
`;
