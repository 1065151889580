export const breakpointSizes = {
  tiny: '400',
  tsmall: '520',
  small: '620',
  smedium: '768',
  medium: '870',
  mlarge: '980',
  large: '1120',
  xlarge: '1260',
  xxlarge: '1400',
  xxxlarge: '1600',
  xxxxlarge: '2000',
};
