import React from 'react';
import styled from 'styled-components';
import { sectionMargins } from '../styles';
import { Container, Video } from './ui';

const StyledExternalVideo = styled.section`
  ${({ reduceGaps }) =>
    reduceGaps ? sectionMargins('30px', '50px') : sectionMargins()};
`;

const ExternalVideo = ({ video, reduceGaps }) => (
  <StyledExternalVideo reduceGaps={reduceGaps}>
    <Container narrow={true}>
      <Video data={video} />
    </Container>
  </StyledExternalVideo>
);

export default ExternalVideo;
