import React from 'react';
import { graphql, Link as GatsbyLink } from 'gatsby';
import styled, { css } from 'styled-components';
import { buildUrl } from '../../utils';

const commonLinkStyles = () => {
  return css`
    display: inline-block;
  `;
};

const StyledLinkInternal = styled(GatsbyLink)`
  ${commonLinkStyles()};
`;

const StyledLinkExternal = styled.a`
  ${commonLinkStyles()};
`;

export const Link = ({ to, children, ...props }) => {
  const LinkComponent = to ? StyledLinkInternal : StyledLinkExternal;

  const slug = typeof to === 'string' ? to : to && to.slug;

  return (
    <LinkComponent
      {...(slug && {
        to: slug === '/' ? slug : buildUrl(slug, to),
      })}
      {...props}
    >
      {children}
    </LinkComponent>
  );
};

export const LinkFragment = graphql`
  fragment LinkFragment on Node {
    ... on DatoCmsHome {
      slug
      locale
    }
    ... on DatoCmsPage {
      slug
      locale
      treeParent {
        slug
        treeParent {
          slug
          treeParent {
            slug
          }
        }
      }
    }
    ... on DatoCmsSector {
      slug
      locale
    }
    ... on DatoCmsResourcesArchive {
      slug
      locale
    }
    ... on DatoCmsCaseStudiesArchive {
      slug
      locale
    }
    ... on DatoCmsCaseStudy {
      slug
      locale
      internal {
        type
      }
    }
    ... on DatoCmsNewsArchive {
      slug
      locale
    }
    ... on DatoCmsNews {
      slug
      locale
      internal {
        type
      }
    }
    ... on DatoCmsBlogArchive {
      slug
      locale
    }
    ... on DatoCmsBlog {
      slug
      locale
      internal {
        type
      }
    }
    ... on DatoCmsReportsArchive {
      slug
      locale
    }
    ... on DatoCmsReport {
      slug
      locale
      internal {
        type
      }
    }
    ... on DatoCmsContact {
      slug
      locale
    }
  }
`;
