import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  brandColours,
  brandFonts,
  fontWeights,
  fontSize,
  standardTransition,
  visuallyHidden,
} from '../styles';
import { Container, Heading, HtmlContent } from './ui';

const StyledTabs = styled.section`
  ${sectionMargins()};
`;

const StyledHeading = styled(Heading)`
  ${visuallyHidden()};
`;

const StyledInner = styled.div`
  display: grid;
  gap: 30px;
  border-bottom: 1px solid ${brandColours.primary};

  ${minBreakpointQuery.small`
    gap: 40px;
    padding-bottom: 30px;
  `}

  ${minBreakpointQuery.smedium`
    grid-template-columns: 1fr 1.7fr;
  `}

  ${minBreakpointQuery.medium`
    gap: 60px;
    padding-bottom: 60px;
  `}

  ${minBreakpointQuery.mlarge`
    gap: 80px;
  `}

  ${minBreakpointQuery.large`
    gap: 100px;
    padding-bottom: 90px;
  `}
`;

const StyledList = styled.ul``;

const StyledItem = styled.li`
  overflow: hidden;
  border-bottom: 1px solid ${brandColours.primary};
`;

const StyledTab = styled.button`
  position: relative;
  padding: 20px 0 20px 50px;
  width: 100%;
  max-width: 385px;
  font-family: ${brandFonts.secondary};
  font-weight: ${fontWeights.bold};
  ${fontSize(19)};
  line-height: 1.5;
  text-align: left;
  background: none;
  border: none;
  transition: ${standardTransition('color, transform')};

  ${minBreakpointQuery.small`
    ${fontSize(22)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(25)};
  `}

  &:before {
    position: absolute;
    content: '→';
    margin-right: 15px;
    top: 28px;
    left: 0;
    ${fontSize(26)};
    line-height: 0.5;

    ${minBreakpointQuery.small`
      ${fontSize(29)};
    `}

    ${minBreakpointQuery.large`
      ${fontSize(32)};
    `}
  }

  ${({ active }) => {
    if (active) {
      return css`
        color: ${brandColours.secondary};
      `;
    } else {
      return css`
        transform: translateX(-50px);
      `;
    }
  }}
`;

const StyledContent = styled.article`
  ${({ active }) => {
    if (!active) {
      return css`
        ${visuallyHidden()};
      `;
    }
  }}
`;

const StyledContentHeading = styled.h3`
  margin: 15px 0;
  font-family: ${brandFonts.secondary};
  ${fontSize(19)};

  ${minBreakpointQuery.small`
    margin-top: 20px;
    margin-bottom: 20px;
    ${fontSize(22)};
  `}

  ${minBreakpointQuery.large`
    margin-top: 25px;
    margin-bottom: 25px;
    ${fontSize(25)};
  `}
`;

export const Tabs = ({ heading, items }) => {
  const [activeTabId, setActiveTabId] = useState(items[0].id);

  return (
    <StyledTabs>
      <Container>
        <StyledHeading>{heading}</StyledHeading>
        <StyledInner>
          <StyledList>
            {items.map(({ id, tab }) => (
              <StyledItem key={id}>
                <StyledTab
                  active={id === activeTabId}
                  onClick={() => {
                    setActiveTabId(id);
                  }}
                >
                  {tab}
                </StyledTab>
              </StyledItem>
            ))}
          </StyledList>
          {items.map(({ id, heading, text }) => (
            <StyledContent key={id} active={id === activeTabId}>
              <StyledContentHeading>{heading}</StyledContentHeading>
              <HtmlContent html={text} />
            </StyledContent>
          ))}
        </StyledInner>
      </Container>
    </StyledTabs>
  );
};

export default Tabs;
