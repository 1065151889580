import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery, sectionMargins, brandColours } from '../styles';
import { Container, Heading, Svg } from './ui';
import arrow from '../images/arrow.inline.svg';

const StyledFeaturedLogos = styled.section`
  ${sectionMargins()};
`;

const StyledHeading = styled(Heading)`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;

  ${minBreakpointQuery.small`
    margin-bottom: 40px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 50px;
  `}
`;

const StyledArrow = styled(Svg)`
  margin: 15px 15px 0 0;
  height: 60px;
  width: 60px;
  stroke: ${brandColours.primary};

  ${minBreakpointQuery.large`
    margin-top: 20px;
    margin-right: 20px;
    height: 80px;
    width: 80px;
  `}
`;

const StyledLogos = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px 20px;

  ${minBreakpointQuery.small`
    column-gap: 25px;
  `}

  ${minBreakpointQuery.smedium`
    grid-template-columns: repeat(4, 1fr);
  `}

  ${minBreakpointQuery.large`
    column-gap: 30px;
  `}
`;

const StyledLogo = styled.img`
  justify-self: center;
  width: 100%;
  max-height: 60px;
  max-width: 160px;

  ${minBreakpointQuery.small`
    max-height: 70px;
    max-width: 180px;
  `}

  ${minBreakpointQuery.large`
    max-height: 80px;
    max-width: 200px;
  `}

  &:nth-child(even) {
    margin-top: 40px;

    ${minBreakpointQuery.small`
      margin-top: 50px;
    `}

    ${minBreakpointQuery.large`
      margin-top: 60px;
    `}
  }
`;

const FeaturedLogos = ({ heading, logos }) => (
  <StyledFeaturedLogos>
    <Container>
      <StyledHeading alt={true}>
        <StyledArrow image={arrow} />
        {heading}
      </StyledHeading>
      <StyledLogos>
        {logos.map(({ url, alt }) => (
          <StyledLogo src={url} alt={alt} loading="lazy" />
        ))}
      </StyledLogos>
    </Container>
  </StyledFeaturedLogos>
);

export default FeaturedLogos;
