import React from 'react';
import styled from 'styled-components';
import CardSlider from './CardSlider';
import TeamCard from './TeamCard';

const StyledFeaturedTeam = styled(CardSlider)``;

const FeaturedTeam = ({ heading, link, items }) => (
  <StyledFeaturedTeam heading={heading} link={link}>
    {items.map(({ id, image, name, role }) => (
      <TeamCard key={id} image={image} name={name} role={role} />
    ))}
  </StyledFeaturedTeam>
);

export default FeaturedTeam;
