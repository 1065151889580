import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  brandColours,
  brandFonts,
  fontSize,
  headingStyles,
} from '../../styles';

export const StyledHtmlContent = styled.div`
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 12px;
    font-family: ${brandFonts.secondary};
    line-height: 1.5;

    ${minBreakpointQuery.small`
      margin-bottom: 14px;
    `}
  }

  h2 {
    ${headingStyles()};
  }

  h3 {
    ${fontSize(22)};

    ${minBreakpointQuery.small`
      ${fontSize(25)};
    `}
  }

  h4 {
    ${fontSize(19)};

    ${minBreakpointQuery.small`
      ${fontSize(22)};
    `}
  }

  h5 {
    ${fontSize(17)};

    ${minBreakpointQuery.small`
      ${fontSize(19)};
    `}
  }

  h6 {
    ${fontSize(15)};

    ${minBreakpointQuery.small`
      ${fontSize(17)};
    `}
  }

  p {
    margin-bottom: 20px;
    ${fontSize(15)};
    line-height: 1.9;

    ${minBreakpointQuery.small`
      margin-bottom: 24px;
      ${fontSize(16)};
    `}

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    position: relative;
    text-decoration: underline;
  }

  ul,
  ol {
    margin-bottom: 20px;

    ${minBreakpointQuery.small`
      margin-bottom: 24px;
    `}

    &:last-child {
      margin-bottom: 0;
    }

    li {
      margin-bottom: 10px;
      ${fontSize(15)};
      line-height: 1.9;

      ${minBreakpointQuery.small`
        margin-bottom: 12px;
        ${fontSize(16)};
      `}

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ul {
    li {
      position: relative;
      padding-left: 20px;

      &:before {
        content: '';
        position: absolute;
        top: 9px;
        left: 0;
        display: inline-block;
        height: 8px;
        width: 8px;
        background-color: ${brandColours.primary};
        border-radius: 50%;
      }
    }
  }

  ol {
    list-style-type: decimal;

    li {
      margin-left: 16px;
    }
  }
`;

export const HtmlContent = ({ html, ...props }) => (
  <StyledHtmlContent dangerouslySetInnerHTML={{ __html: html }} {...props} />
);
