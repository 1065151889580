import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { minBreakpointQuery, maxBreakpointQuery } from '../styles';
import { Container, Heading, Button } from './ui';

const StyledImageLinkCards = styled.section``;

const StyledHeading = styled(Heading)`
  margin-bottom: 30px;
  text-align: center;

  ${minBreakpointQuery.small`
    margin-bottom: 40px;
  `}

  ${minBreakpointQuery.medium`
    margin-bottom: 50px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 60px;
  `}
`;

const StyledItems = styled.div`
  display: grid;
  gap: 30px 20px;

  ${minBreakpointQuery.smedium`
    grid-template-columns: repeat(3, 1fr);
  `}
`;

const StyledItem = styled.article`
  position: relative;
`;

const StyledImage = styled(GatsbyImage)`
  border-radius: 10px;

  ${maxBreakpointQuery.smedium`
    max-height: 400px;
  `}
`;

const StyledButton = styled(Button)`
  position: absolute;
  right: 30px;
  bottom: 30px;
  left: 30px;

  ${minBreakpointQuery.tsmall`
    right: 40px;
    bottom: 40px;
    left: 40px;
  `}

  ${minBreakpointQuery.smedium`
    right: 30px;
    bottom: 30px;
    left: 30px;
  `}

  ${minBreakpointQuery.mlarge`
    right: 40px;
    bottom: 40px;
    left: 40px;
  `}

  ${minBreakpointQuery.xlarge`
    right: 50px;
    bottom: 50px;
    left: 50px;
  `}
`;

const ImageLinkCards = ({ heading, items, ...props }) => (
  <StyledImageLinkCards {...props}>
    <Container>
      {heading && <StyledHeading alt={true}>{heading}</StyledHeading>}
      <StyledItems>
        {items.map(({ id, image, link }) => (
          <StyledItem key={id}>
            <StyledImage image={image.gatsbyImageData} alt={image.alt} />
            <StyledButton to={link.page} alt={true}>
              {link.text}
            </StyledButton>
          </StyledItem>
        ))}
      </StyledItems>
    </Container>
  </StyledImageLinkCards>
);

export default ImageLinkCards;

export const ImageLinkCardsItemFragment = graphql`
  fragment ImageLinkCardsItemFragment on DatoCmsImageLinkCard {
    id
    image {
      gatsbyImageData(width: 710, height: 800)
      alt
    }
    link {
      text
      page {
        ...LinkFragment
      }
    }
  }
`;
